import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MobileTestingCalendarService } from '../../services/mobile-testing.service';
import { RegionsI } from '@bodyanalytics/data-models-ui';


@Component({
  selector: 'bodyanalytics-events-filtering',
  templateUrl: './events-filtering.component.html',
  styleUrls: ['./events-filtering.component.scss'],
})
export class EventsFilteringComponent implements OnInit {
  filterForm: FormGroup;
  filterOptions = [
    { label: 'Gym Name', value: 'gymName' },
    { label: 'Zip Code', value: 'zipcode' },
    { label: 'Region', value: 'region' }
  ];
  selectedFilterOption = this.filterOptions[2];
  regions: RegionsI[] = [];
 
  @Output() filterChanged = new EventEmitter<{ searchQuery: string, searchType: string }>();

  constructor(
    private fb: FormBuilder,
    private mobileTestingCalendarService: MobileTestingCalendarService) 
  {
    this.filterForm = this.fb.group({
      searchQuery: ['', Validators.required],
      searchType: [this.selectedFilterOption.value, Validators.required]
    });

    this.filterForm.get('searchType')?.valueChanges.subscribe(type => {
      this.filterForm.get('searchQuery')?.setValue('');
      this.filterForm.get('searchQuery')?.markAsUntouched();

      const currentFilter = this.filterOptions.find(option => option.value === type);
      if (currentFilter) {
        this.selectedFilterOption = currentFilter;
      }

      this.updateValidation(type);
    });
  }

  ngOnInit(): void {
    this.getActiveRegions();
  }

  getActiveRegions() {
    this.mobileTestingCalendarService.getActiveRegions().subscribe(regions => {
      this.regions = regions;

      const defaultDallasRegion = this.regions.find(region => region.regionName === 'Dallas');
      if (defaultDallasRegion) {
        this.filterForm.get('searchQuery')?.setValue(defaultDallasRegion.regionName);
        this.filterEvents();
      }
    });
  }

  updateValidation(type: string) {
    const searchControl = this.filterForm.get('searchQuery');

    if (type === 'gymName') {
      searchControl?.setValidators([Validators.required, Validators.minLength(3)]);
    } else if (type === 'zipcode') {
      searchControl?.setValidators([Validators.required, Validators.pattern(/^\d{5}$/)]);
    } else if (type === 'region') {
      searchControl?.setValidators([Validators.required]);
    }

    searchControl?.updateValueAndValidity();
  }

  selectRegion(region: RegionsI) {
    this.filterForm.get('searchQuery')?.setValue(region.regionName);
    this.filterForm.get('searchQuery')?.markAsTouched();
    this.filterForm.get('searchQuery')?.markAsDirty();
  }
  
  filterEvents() {
    if (this.filterForm.invalid) {
      this.filterForm.get('searchQuery')?.markAsTouched();
      this.filterForm.markAllAsTouched();
      return;
    }
  
    this.filterChanged.emit(this.filterForm.value);
  }
}
