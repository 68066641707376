<div class="container mt-5 text-center">
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card shadow-lg">

        <div class="card-body py-5">
          <router-outlet />
        </div>
      </div>
    </div>
  </div>
