import { ChangeDetectionStrategy, Component, input, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { DiscountI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, MaterialModule, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { CurrencyPipe, DatePipe, JsonPipe, NgFor, NgIf } from '@angular/common';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { animations } from './manage-discounts-table.animations';

@Component({
  selector: 'bodyanalytics-manage-discounts-table',
  templateUrl: './manage-discounts-table.component.html',
  styleUrls: ['./manage-discounts-table.component.scss'],
  standalone: true,
  animations,
  providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatTableModule, DatePipe, RouterLink, MatSortModule, JsonPipe, CurrencyPipe, MaterialModule, NgIf, NgFor],
})
export class ManageDiscountsTableComponent extends BodyanalyticsBaseComponent {
  protected discountDetail = RouterUrlsEnum.AUTH_ADMIN_MANAGE_DISCOUNTS_DETAILS;

  protected displayedColumns = ['discountCode', 'discountType', 'discountAmount', 'discountDescription'];
  protected columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  protected expandedElement?: DiscountI;

  entities = input.required<DiscountI[]>();

  // Table data source
  dataSource = new MatTableDataSource<DiscountI>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // Workaround to notify the table about data changes
    this.dataSource.paginator.page.subscribe(() => this.updateTable());
    this.dataSource.sort.sortChange.subscribe(() => this.updateTable());
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.updateTable();
  }
  // Explicitly trigger change detection for pagination, sort, and filter
  private updateTable() {
    this.dataSource._updateChangeSubscription();
  }
}
