import { CommonModule, NgClass, NgIf, NgFor } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, effect, input, ViewChild } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { RouterLink } from '@angular/router';
import { BookingI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, MaterialModule, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { animations } from './manage-booking-table.animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'bodyanalytics-manage-booking-table',
  templateUrl: './manage-booking-table.component.html',
  styleUrls: ['./manage-booking-table.component.scss'],
  standalone: true,
  animations,
  providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MaterialModule, CommonModule, NgClass, NgIf, NgFor, RouterLink],
})
export class ManageBookingsTableComponent extends BodyanalyticsBaseComponent implements AfterViewInit {
  protected bookingDetail = RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS_DETAILS;

  protected displayedColumns = ['customerId', 'eventId'];
  protected columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  protected expandedElement?: BookingI;

  entities = input.required<BookingI[]>();

  // Table data source
  dataSource = new MatTableDataSource<BookingI>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor() {
    super();
    // React to changes in entities and update the table's data source
    effect(() => {
      const data = this.entities(); // Get the updated entities
      this.dataSource.data = data; // Set it to the MatTableDataSource
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // Workaround to notify the table about data changes
    this.dataSource.paginator.page.subscribe(() => this.updateTable());
    this.dataSource.sort.sortChange.subscribe(() => this.updateTable());
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.updateTable();
  }
  // Explicitly trigger change detection for pagination, sort, and filter
  private updateTable() {
    this.dataSource._updateChangeSubscription();
  }
}
