<bodyanalytics-event-header [item]="eventData"></bodyanalytics-event-header>

<div class="py-12">
  <div class="container">
    <div class="row reschedule-time-selection-container" *ngIf="isRescheduleCheckout">
      <div class="col-md-7 col-sm-12 px-md-4 px-sm-12">
        <div class="mb-5">
          <div class="time-selection">
            <div class="h-100 pt-3 mt-5 bg-white rounded-3">
              <h3 class="mb-3">Select time</h3>
              <!-- <span class="tiny"> Please select a time before proceeding.</span> -->

              <div class="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-md-5 g-2 mt-3">
                <div class="col-md-3 col-sm-12" *ngFor="let timeSlot of availableSlots">
                  <button
                    type="button"
                    [ngClass]="{ 'btn-success': timeSlot.isSelected, 'btn-light': !timeSlot.isSelected }"
                    class="btn shadow-sm w-100 time-slot border"
                    (click)="selectTimeSlot(timeSlot)">
                    {{ timeSlot.startTime }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12 py-5 px-5 mt-5 border">


        <button
          class="btn btn-danger w-100 my-5"
          (click)="continueToRescheuleBooking()"
          [disabled]="!isBookingSlotSelected">
          Sign up
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7 col-sm-12">
        <div class="mb-10">
          <div class="mb-5">
            <h3 class="mb-3">Description</h3>
            <p class="lead text-dark text-wrap px-md-2 px-sm-2">
              Join us for a unique opportunity to take charge of your health and fitness with AppliedBioX at
              {{ eventData.testEventAtClub.clubName }}. Whether you're an athlete looking to optimize your performance
              or someone on a fitness journey seeking to understand your body composition, this event is for you.
            </p>
          </div>
          <div>
            <div class="mb-5">
              <h3 class="mb-2">Date and time</h3>
              <p class="mb-0 mt-0">
                <span><i class="bx bxs-calendar me-2 text-danger"></i></span>
                {{ formattedEventDate }} from {{ formattedStartTime }} - {{ formattedEndTime }}
              </p>
            </div>
            <div class="mb-5">
              <h3 class="mb-2">What to Expect</h3>
              <ul>
                <li>Please arrive at least 15 minutes before your scheduled appointment time.</li>
                <li>Bring a bathing suit to be measured in our tank of truth.</li>
                <li>Hydrstatic weighting is quick and easy. You will be in and out within 15 mins.</li>
                <li>Mobile testing results will be provided on the spot, along with personalized recommendations.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- event sidebar -->
      <div class="col-lg-4 col-md-4 col-12">
        <div class="mb-5">
          <!--map-->
          <!-- <div class="my-google-map">
            <google-map [width]="null" [height]="null" [options]="mapOptions"></google-map>
          </div> -->
        </div>
        <bodyanalytics-event-information-card [item]="eventData"></bodyanalytics-event-information-card>
        <div class="row" *ngIf="!isRescheduleCheckout">
          <button class="btn btn-warning w-100" (click)="continueToBooking()">Sign up</button>
        </div>
      </div>
    </div>
  </div>
</div>
