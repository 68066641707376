import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'lib-license-shell',
  standalone: true,
  imports: [CommonModule,RouterModule],
  templateUrl: './license-shell.component.html',
  styleUrl: './license-shell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LicenseShellComponent {

}
