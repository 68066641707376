import { Route } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { CreateTenantComponent } from './create-tenant/create-tenant.component';
import { RegisterShellComponent } from './register-shell/register-shell.component';
import { AdminRegisterService, provideAdminRegisters } from '@bodyanalytics/front/admin/registration/domain';

export const adminRegisterUiRoutes: Route = {
  path: 'auth/admin-register',
  component: RegisterShellComponent,
  canActivate: [AuthGuard],
  providers: [provideAdminRegisters(), AdminRegisterService,],
  children: [
    { path: '', pathMatch: 'full', redirectTo: 'create-tenant' },
    {
      path: 'create-tenant',
      component: CreateTenantComponent,
      pathMatch: 'full',
    },
  ],
};
