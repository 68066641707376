import { Component, EventEmitter,input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { TestingEventI, OperatorI, ClubI, TruckI } from '@bodyanalytics/data-models-ui';
import {
  BodyanalyticsBaseComponent,
  ManageEventDeleteDialogComponent,
  RouterUrlsEnum,
  eventStatus,
  eventTimes,
  signupInterval,
} from '@bodyanalytics/ui-core';
 import { catchError, of, tap } from 'rxjs';
import { ManageTestingEventService } from '@bodyanalytics/front/admin/manage-events/domain';
import { NgClass, NgIf, NgFor } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { formatISO, parseISO } from 'date-fns';

@Component({
  selector: 'bodyanalytics-manage-events-form',
  templateUrl: './manage-events-form.component.html',
  styleUrls: ['./manage-events-form.component.scss'],
  standalone:true,
  imports: [ReactiveFormsModule, NgClass, NgIf,NgFor, RouterLink,],

})
export class ManageEventsFormComponent extends BodyanalyticsBaseComponent implements OnInit {
  @Output() submitFormEvent = new EventEmitter<any>();
  // @Input()
  // item!: TestingEventI | null;

  item = input.required<TestingEventI>();

  public EVENT_ID = '';
  public CLUB_ID = '';
  formsubmit = false;
  public operators: OperatorI[] = [];
  public trucks: TruckI[] = [];
  public eventLocation: ClubI[] = [];
  // public testingEventDetailsForm: FormGroup = {} as FormGroup;
  signupIntervalJson = [...signupInterval];

  eventStatusJson = [...eventStatus];

  eventTimesJson = [...eventTimes];
  public testingEventDetailsForm = new FormGroup({
    clubName: new FormControl('', [Validators.required]),
    testEventName: new FormControl('', [Validators.required]),
    testEventDate: new FormControl('', [Validators.required]),
    startTime: new FormControl('', [Validators.required]),
    endTime: new FormControl('', [Validators.required]),
    signupInterval: new FormControl('', [Validators.required]),
    isAllowedSignupOnline: new FormControl(false, [Validators.required]),
    isPaymentRequired: new FormControl(false, [Validators.required]),
    isTestingEventPrivate: new FormControl(false, [Validators.required]),
    assignedTruckId: new FormControl('', [Validators.required]),
    assignedOperatorId: new FormControl('', [Validators.required]),
    eventStatus: new FormControl('', [Validators.required]),
    note: new FormControl('', [Validators.required]),
    status: new FormControl('', [Validators.required]),
    testEventAtClubId: new FormControl('', [Validators.required]),

    testEventDescription: new FormControl('', [Validators.required]),
    testEventPhoto: new FormControl('', [Validators.required]),
  });

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private manageTestingEventService: ManageTestingEventService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.operators = [];
    this.eventLocation = [];
    this.trucks = [];

    this.subscriptions.barrel = this.manageTestingEventService.getOperatorsList().subscribe(response => {
      this.operators = response;
    });
    this.subscriptions.barrel = this.manageTestingEventService.getTruckList().subscribe(response => {
      this.trucks = response;
    });

    this.subscriptions.barrel = this.manageTestingEventService.getClubsList().subscribe(response => {
      this.eventLocation = response;
    });
    this.getTestingEvent();
    // this.EVENT_ID = this.item().testingevent_id as string;
    // this.CLUB_ID = this.item().club_testingevent_id as string;
  }

  public getTestingEvent(): void {
    // if (this.testingEventDetailsForm.valid) {
    // if (this.item as TestingEventI) {
    // const isoStartTime = this.convertTimeToISOString(this.item.startTime);
    // const isoEndTime = this.convertTimeToISOString(this.item.endTime);
    // const eventDate = new Date(this.item.testEventDate).toISOString().split('T')[0];
    this.EVENT_ID = this.item().id as string;
    this.CLUB_ID = this.item().testEventAtClub.id as string;
    this.testingEventDetailsForm.patchValue({
      testEventName: this.item().testEventName,
      clubName: this.item().testEventAtClub.clubName,
      testEventDescription: this.item().testEventDescription,
      testEventDate: this.item().testEventDate,
      startTime: this.item().startTime,
      endTime: this.item().endTime,
      // signupInterval: this.item().signupInterval as string,
      isAllowedSignupOnline: this.item().isAllowedSignupOnline as boolean,
      isPaymentRequired: this.item().isPaymentRequired as boolean,
      isTestingEventPrivate: this.item().isTestingEventPrivate as boolean,
      assignedOperatorId: this.item().assignedOperatorId as string,
      assignedTruckId: this.item().assignedTruckId as string,
      // status: this.item().status as any,
      // operators: this.item().assignedOperator,
      // testEventAtClubId: this.item().testEventAtClub.id,
      // note: this.item(). as string,
    });
    this.testEventDateSelected.patchValue(this.item().testEventDate as string);
    this.startTimeSelected.patchValue('8:00');
    this.endTimeSelected.patchValue('14:00');
    this.signupIntervalSelected.patchValue('FIFTEEN_MIN');

  }

  public updateTestingEvent(): void {

    // Parse the string to a Date object and format it to ISO
const testEventDate = formatISO(parseISO(this.testingEventDetailsForm.value.testEventDate!));
    this.submitFormEvent.emit({
       testEventDate: testEventDate,
      startTime: this.testingEventDetailsForm.value.startTime,
      endTime: this.testingEventDetailsForm.value.endTime,
      signupInterval: this.testingEventDetailsForm.value.signupInterval,
      assignedTruckId: this.testingEventDetailsForm.value.assignedTruckId,
      isAllowedSignupOnline: this.testingEventDetailsForm.value.isAllowedSignupOnline,
      isPaymentRequired: this.testingEventDetailsForm.value.isPaymentRequired,
      isTestingEventPrivate: this.testingEventDetailsForm.value.isTestingEventPrivate,
      testEventDescription: this.testingEventDetailsForm.value.testEventDescription,
      assignedOperatorId: this.testingEventDetailsForm.value.assignedOperatorId,
      //   status: this.testingEventDetailsForm.value.status,
      //   note: this.testingEventDetailsForm.value.note,
      bodyFatTestEventClubId: this.testingEventDetailsForm.value.testEventAtClubId,
    });
  }

  openModal(content: any) {
    // this.modalService.open(content, { backdropClass: 'light-blue-backdrop', size: 'lg' });
  }

  convertTimeToISOString(time: string): string {
    const dateToday: Date = new Date();
    // Split the hours and minutes
    const [hours, minutes] = time.split(':');

    // Set the hours and minutes to the specified date
    dateToday.setHours(Number(hours), Number(minutes), 0, 0);

    // Return the ISO string
    const testDate = new Date(dateToday);

    return testDate.toISOString();
  }

  protected goToSignupPage() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS}/history/${this.EVENT_ID}`);
  }
  protected duplicateEvent() {
    this.subscriptions.barrel = this.manageTestingEventService
      .duplicateTestingEvent(`${this.EVENT_ID}`, this.item() as TestingEventI)
      .pipe(
        tap(() => {
          console.log(`duplicateTestingEvent - ${this.EVENT_ID}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
  protected sendEmailReminder() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EMAILS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
  }
  protected sendSmsReminder() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
  }
  protected cancelEvent() {
    this.subscriptions.barrel = this.manageTestingEventService
      .deleteTestingEvent(`${this.EVENT_ID}`)
      .pipe(
        tap(() => {
          console.log(`cancelEvent - ${this.EVENT_ID}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
  protected viewClubDetails() {
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_CLUBS_DETAILS}/${this.CLUB_ID}`);
  }
  protected showSignupList() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS}/list/${this.EVENT_ID}`);
  }
  openDeleteDialog(originalClubId: string): void {
    const dialogRef = this.dialog.open(ManageEventDeleteDialogComponent, {
      width: '500px',
      // other configuration
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {

        this.processCancellation(originalClubId);
      } else {
        // this.loadingCheckInSession = false;
      }
    });
  }

  processCancellation(originalClubId: string) {
    // this.isSearchSearched = false;
    // this.spinnerTitle = 'Cancelling';

    this.subscriptions.barrel = this.manageTestingEventService.deleteTestingEvent(originalClubId).subscribe({
      next: response => {
        // this.loadingCheckInSession = false;
        // this.manageSignup = this.manageSignup.filter(booking => booking.id !== originalBookingId);
      },
      error: error => {
        console.error('Error canceling booking:', error);

      },
    });
  }


  /**
   * Returns form
   */
  get form() {
    return this.testingEventDetailsForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
  formSubmit() {
    this.formsubmit = true;
  }

  public get testEventDescriptionSelected(): AbstractControl {
    return this.testingEventDetailsForm.get('testEventDescription') as AbstractControl;
  }
  public get testEventStatusSelected(): AbstractControl {
    return this.testingEventDetailsForm.get('status') as AbstractControl;
  }

  // public get testEventNameSelected(): AbstractControl {
  //   return this.testingEventDetailsForm.get('testEventName') as AbstractControl;
  // }

  public get testEventDateSelected(): AbstractControl {
    return this.testingEventDetailsForm.get('testEventDate') as AbstractControl;
  }

  public get signupIntervalSelected(): AbstractControl {
    return this.testingEventDetailsForm.get('signupInterval') as AbstractControl;
  }

  public get startTimeSelected(): AbstractControl {
    return this.testingEventDetailsForm.get('startTime') as AbstractControl;
  }

  public get endTimeSelected(): AbstractControl {
    return this.testingEventDetailsForm.get('endTime') as AbstractControl;
  }

  public get operatorSelected(): AbstractControl {
    return this.testingEventDetailsForm.get('assignedOperatorId') as AbstractControl;
  }

  public get truckSelected(): AbstractControl {
    return this.testingEventDetailsForm.get('trucks') as AbstractControl;
  }

  public get clubSelected(): AbstractControl {
    return this.testingEventDetailsForm.get('testEventAtClubId') as AbstractControl;
  }
}
