<section id="manage-bodymeasurements-details" class="dashboard-container dashboard">
  <div class="container">
    <div class="row">
      <div class="col-md-12 bg-white p-2">
        <div class="form w-100 pb-2">
          <div class="my-5">
            <a (click)="location.back()" class="btn btn-link text-decoration-none my-3" type="link">
              <i class="bx bx-arrow-back"></i>Go back
            </a>

            <h4 class="h5">Body Assesment Details</h4>
          </div>

          @if(data(); as data) {
          <bodyanalytics-manage-bodymeasurements-form
            [item]="data"
            (submitFormEvent)="processBodyMeasurementUpdate($event)" />
          }
        </div>
      </div>
    </div>
  </div>
</section>
