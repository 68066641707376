import { inject } from '@angular/core';
import { TenantI, PricingPlanI } from '@bodyanalytics/data-models-ui';
import { tapResponse } from '@ngrx/operators';
import { signalStore, withState, withMethods, patchState, withHooks } from '@ngrx/signals';
import { withEntities, setAllEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap } from 'rxjs';
import { AdminRegisterService } from './admin-register.service';



export const AdminRegisterStore = signalStore(
  withState({ loading: false }),
  withEntities<TenantI>(),
  withEntities({ collection: 'plans', entity: <PricingPlanI>{} }), // Fix the type specification here

  withMethods((s, service = inject(AdminRegisterService)) => ({
    loadPlans: rxMethod<void>(
      pipe(
        switchMap(() =>
          service.getTenantPlans().pipe(
            tapResponse({
              next: (entities: PricingPlanI[]) => patchState(s, setAllEntities(entities, { collection: 'plans' })), // Specify idKey
              error: error => {
                console.error(error);
                patchState(s, { loading: false });
              },
            }),
          ),
        ),
      ),
    ),
  })),
  withHooks({
    onInit: s => {
      s.loadPlans();
    },
  }),
);
