<section id="manage-orders-dashboard" class="dashboard bg-white">
  <div class="container">
    <div class="row text-white">
      <div class="col-md-12 bg-white p-2 mx-auto">
        <div class="w-100 pb-2">

          <div class="card-body border-0">
            <div class="manage-orders-container">
              <div class="row">
                <div class="border mx-auto">
                  <div class="card border-0">
                    <div class="card-body border-0">
                      <div class="row no-gutters">
                        <bodyanalytics-manage-orders-table [entities]="entities()" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>
