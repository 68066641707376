
  <table mat-table [dataSource]="dataSource">
    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>
        No.
        <!-- <input type="checkbox"  (change)="onCheckboxChange($event)" /> -->
        <!-- <mat-checkbox class="example-margin" (change)="onCheckboxChange($event)">Checked</mat-checkbox> -->
      </th>

      <td mat-cell *matCellDef="let element">
        <input type="checkbox" [value]="element.id" (change)="onCheckboxChange($event)" />
        {{ element.id }}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="testEventName">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">{{ element.testEventName }}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.status }}</td>
    </ng-container>

    <!-- Symbol Column -->
    <!-- <ng-container matColumnDef="symbol">
      <th mat-header-cell *matHeaderCellDef> Symbol </th>
      <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
    </ng-container> -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
  </mat-paginator>
