<section id="admin-register" class="dashboard bg-white">
  <div class="container">
    <div class="row text-white">
      <div class="col-md-12 bg-white p-2 mx-auto">
        <div class="w-100 pb-2">
          <div>
            <router-outlet />
          </div>

        </div>
      </div>
    </div>
  </div>
</section>
