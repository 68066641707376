import { Component, inject } from '@angular/core';
import { ManageTestingEventsStore } from '@bodyanalytics/front/admin/manage-events/domain';
import { ManageEventsTableComponent } from '@bodyanalytics/front/admin/manage-events/ui';
import { NgIf } from '@angular/common';

@Component({
  selector: 'bodyanalytics-manage-events-dashboard',
  templateUrl: './dashboard.component.html',
  standalone:true,
  imports:[ManageEventsTableComponent, NgIf],
  styleUrls: ['./dashboard.component.scss'],
})
export class ManageEventsDashboardComponent {
  // protected testingEventsData = toSignal(inject(ManageTestingEventService).load());
  entities = inject(ManageTestingEventsStore).entities;

}
