<div class="table-container">
  <mat-form-field class="filter-field">
    <mat-label>Filter</mat-label>
    <input matInput (input)="applyFilter($event)" placeholder="Search">
  </mat-form-field>
  <table [dataSource]="dataSource" [multiTemplateDataRows]="true" class="mat-elevation-z8" mat-table matSort>
    @for (column of displayedColumns; track $index) {
    <ng-container matColumnDef="{{ column }}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ transformToTitleCase(column) }}</th>
      <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
    </ng-container>
    }
    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
      <td mat-cell *matCellDef="let element">
        <button [routerLink]="[clubDetail, element.id]" class="list-group-item list-group-item-action">
          <i class="bx bxs-pencil"></i> Edit
        </button>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand" class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"></tr>
  </table>
  <br/>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page"></mat-paginator>
</div>
