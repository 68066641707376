import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import { ENV } from '@bodyanalytics/app-config';
import { TenantResponse, TenantI, OperatorI, PricingPlanI } from '@bodyanalytics/data-models-ui';
import { ApiLocalRoutesEnum, SharedLogService, SharedErrorService } from '@bodyanalytics/ui-core';
import { Observable, map, catchError, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminRegisterService {
  private env = inject(ENV);
  private tenantUrl = `${ApiLocalRoutesEnum.API_SUFFIX_ADMIN_REGISTERATION}`;
  private subPlansUrl = `${ApiLocalRoutesEnum.API_SUFFIX_ADMIN_REGISTERATION}/plans`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(
    private http: HttpClient,
    private sharedLogService: SharedLogService,
    private sharedErrorService: SharedErrorService,
    @Inject(ENV) private appConfig: any,
  ) {}

  createTenant(tenant: any): Observable<TenantResponse> {
    const url = `${this.appConfig.api}/${this.tenantUrl}`;

    return this.http.post<TenantResponse>(url, tenant).pipe(
      map(response => ({
        success: true,
        data: response as TenantI,
      })),
      catchError(this.handleError),
    );
  }

  // public loadPlans(): Observable<PricingPlanI[]> {
  //   return this.http.get<PricingPlanI[]>(`${this.env.api}/${this.subPlansUrl}`);
  // }

  deleteTenant(id: string): Observable<OperatorI> {
    const url = `${this.appConfig.api}/${this.tenantUrl}/${id}`;

    return this.http.delete<OperatorI>(url, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`deleted operator id=${id}`)),
      catchError(this.sharedErrorService.handleError<OperatorI>('deleteOperator')),
    );
  }

  updateTenant(id: string, tenant: TenantI): Observable<TenantResponse> {
    return this.http.post<TenantResponse>(this.tenantUrl, tenant).pipe(
      map(response => ({
        success: true,
        data: response as TenantI,
      })),
      catchError(this.handleError),
    );
  }

  load(): Observable<TenantI[]> {
    const url = `${this.appConfig.api}/${this.tenantUrl}`;
    console.log(url);
    return this.http.get<TenantI[]>(url).pipe(
      tap(_ => console.log('fetched tenants')),
      catchError(this.sharedErrorService.handleError<TenantI[]>('getTenants', [])),
    );
  }

  getTenant(id: string): Observable<TenantI> {
    const url = `${this.env.api}/${this.tenantUrl}/details/${id}`;
    return this.http.get<TenantI>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched tenant id=${id}`)),
      catchError(this.sharedErrorService.handleError<TenantI>(`tenant id=${id}`)),
    );
  }

  getTenantPlans(): Observable<PricingPlanI[]> {
    const url = `${this.appConfig.api}/${this.tenantUrl}/plans`;
    console.log(url);
    return this.http.get<PricingPlanI[]>(url).pipe(
      tap(_ => console.log('fetched tenants plans')),
      catchError(this.sharedErrorService.handleError<PricingPlanI[]>('getTenants plans', [])),
    );
  }

  private handleError(error: HttpErrorResponse): Observable<TenantResponse> {
    let errorMessage = 'An error occurred';

    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = error.error.message;
    } else {
      // Server-side error
      errorMessage = error.error?.message || `Error Code: ${error.status}`;
    }

    return throwError(() => ({
      success: false,
      error: errorMessage,
    }));
  }
}
