import { AfterViewInit, Component, effect, input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { catchError, of, tap } from 'rxjs';
import { Router, RouterLink } from '@angular/router';
import { BodyanalyticsBaseComponent, MaterialModule, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { TestingEventI } from '@bodyanalytics/data-models-ui';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ManageTestingEventService } from '@bodyanalytics/front/admin/manage-events/domain';
import { NgClass, NgIf, NgFor, CommonModule } from '@angular/common';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { format, utcToZonedTime } from 'date-fns-tz';

@Component({
  selector: 'bodyanalytics-manage-events-table',
  templateUrl: './manage-events-table.component.html',
  styleUrls: ['./manage-events-table.component.scss'],
  standalone: true,
  providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }],
  imports: [NgClass, NgIf, NgFor, RouterLink, MaterialModule, CommonModule,MatSortModule],
})
export class ManageEventsTableComponent extends BodyanalyticsBaseComponent implements AfterViewInit {
  protected eventDetail = RouterUrlsEnum.AUTH_ADMIN_MANAGE_EVENTS_DETAILS;
   submitted = false;
  checked = '';
  entities = input.required<TestingEventI[]>();
  public displayedColumns: string[] = ['testEventName', 'testEventDate', 'startTime', 'endTime', 'isTestingEventPrivate','status'];
  protected columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  protected expandedElement?: TestingEventI;
  public testingEventsForm!: FormGroup;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  // Table data source
  dataSource = new MatTableDataSource<TestingEventI>();

  isTestingEventTableEnabled = true;
  isTestingEventIdSelected = '';
  public testingEventTableData: TestingEventI[] = [];

  constructor(
    private manageTestingEventService: ManageTestingEventService,
    private fb: FormBuilder,
    private router: Router,
  ) {
    super();
    // React to changes in entities and update the table's data source
    effect(() => {
      const data = this.entities(); // Get the updated entities
      this.dataSource.data = data; // Set it to the MatTableDataSource
    });
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // Workaround to notify the table about data changes
    this.dataSource.paginator.page.subscribe(() => this.updateTable());
    this.dataSource.sort.sortChange.subscribe(() => this.updateTable());
  }

  onCheckboxChange($event: Event) {
    const target: HTMLInputElement = $event.target as HTMLInputElement;
    if (target.checked) {
      this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EVENTS_DETAILS}/${target.value}`);
    }
  }

  openModal(deleteContent: any, id: string) {
    // const id = this.route.snapshot.paramMap.get('id') as string;
    // this.modalService.open(deleteContent, { backdropClass: 'light-blue-backdrop', size: 'lg' });
    this.isTestingEventIdSelected = id;
  }

  deleteTestingEvent() {
    this.subscriptions.barrel = this.manageTestingEventService
      .deleteTestingEvent(this.isTestingEventIdSelected)
      .pipe(
        tap(() => {
          this.submitted = true;
          location.reload();
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
  /**
   * Modal Open
   * @param content modal content
   */

  public createTestingEvent(signupDetails: any): void {
    this.subscriptions.barrel = this.manageTestingEventService
      .addTestingEvent(signupDetails)
      .pipe(
        tap(() => {
          this.submitted = true;
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.updateTable();
  }
  // Explicitly trigger change detection for pagination, sort, and filter
  private updateTable() {
    this.dataSource._updateChangeSubscription();
  }

  formatCTTime(time: string): string {
    const timeZone = 'America/Chicago'; // Central Time

    // Convert the test event date to Central Time (CT)
    const eventDateCT = utcToZonedTime(time, timeZone);

    // Format the date for display
    return format(eventDateCT, 'h:mm a');
  }
}
