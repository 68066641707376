import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'lib-license-error',
  standalone: true,
  imports: [],
  templateUrl: './license-error.component.html',
  styleUrl: './license-error.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LicenseErrorComponent {

}
