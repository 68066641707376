<div class="filter-container bg-light bg-darker p-5 rounded">
  <h5 class="mb-2">Search by <em>{{ selectedFilterOption.label }}</em></h5>
  
  <form [formGroup]="filterForm">
    <div class="mb-3" *ngIf="['gymName', 'zipcode'].includes(this.selectedFilterOption.value)">
      <input
        type="text" 
        class="form-control" 
        formControlName="searchQuery"
        [class.is-invalid]="filterForm.get('searchQuery')?.invalid && filterForm.get('searchQuery')?.touched"
      />
      <div *ngIf="filterForm.get('searchQuery')?.errors?.['required'] && filterForm.get('searchQuery')?.touched" class="invalid-feedback">
        Please enter search criteria before proceeding.
      </div>
      <div *ngIf="filterForm.get('searchQuery')?.errors?.['minlength'] && filterForm.get('searchQuery')?.touched" class="invalid-feedback">
        Please enter at least 3 characters for gym search.
      </div>
      <div *ngIf="filterForm.get('searchQuery')?.errors?.['pattern'] && filterForm.get('searchQuery')?.touched" class="invalid-feedback">
        Please enter a valid 5-digit zip code.
      </div>
    </div>
  
    <div *ngIf="selectedFilterOption.value === 'region'" class="region-boxes">
      <button 
        *ngFor="let region of regions"
        type="button"
        class="btn region-box"
        [class.selected]="filterForm.get('searchQuery')?.value === region.regionName"
        (click)="selectRegion(region)"
      >
        {{ region.regionName }}
      </button>

      <div *ngIf="filterForm.get('searchQuery')?.errors?.['required'] && filterForm.get('searchQuery')?.touched" class="invalid-feedback d-block mt-n1">
        Please select a region before proceeding.
      </div>
    </div>

    <div class="mb-3 d-flex align-items-center">
      <div *ngFor="let option of filterOptions" class="form-check me-3 d-flex align-items-center">
        <input 
          class="form-check-input me-2" 
          type="radio" 
          [id]="option.value" 
          [value]="option.value" 
          formControlName="searchType"
        />
        <label class="form-check-label" [for]="option.value">{{ option.label }}</label>
      </div>
    </div>
  </form>

  <button class="btn btn-primary w-100" (click)="filterEvents()">Find</button>
</div>
