<div class="row mx-n2">
  <ng-container *ngIf="testEvents!.length > 0; else noEvents">
    <div class="col-lg-3 col-md-4 col-sm-6 px-2 mb-grid-gutter" *ngFor="let testEvent of testEvents; let index = index">
      <div class="card product-card-alt" *ngIf="testEvent.testEventAtClub">
        <div class="product-thumb">
          <div class="product-card-actions">
            <button class="btn btn-light btn-icon btn-shadow fs-base mx-2" type="button">
              <i class="ci-cart"></i>
            </button>
          </div>
          <!-- <a class="product-thumb-overlay" href="marketplace-single.html"></a> -->
          <img src="https://picsum.photos/230/100" alt="Event {{ testEvent.testEventAtClub.clubName }} primary image" />
        </div>
        <div class="card-body">
          <div class="d-flex flex-wrap justify-content-between align-items-start pb-2">
            <div class="fs-xs me-1">
              <p class="small mb-1">
                <!--icon-->
                <span><i class="bx bxs-calendar me-2 text-danger"></i></span>
                {{ testEvent.testEventDate | date: 'longDate' }}
              </p>
            </div>
          </div>
          <h3 class="product-title fs-sm mb-2">
            <a>{{ testEvent.testEventName ? testEvent.testEventName : testEvent.testEventAtClub.clubName }}</a>
          </h3>
          <div class="text-muted fs-xs me-1">
            <div class="flex-content">
              <div><i class="bx bx-pin me-2 text-danger"></i></div>

              <div>
                <p class="small mb-3">
                  {{ testEvent.testEventAtClub.clubName }} <br />
                  {{ testEvent.testEventAtClub.address1 }}
                  {{ testEvent.testEventAtClub.city }}, {{ testEvent.testEventAtClub.state }}
                  {{ testEvent.testEventAtClub.zipCode }}
                </p>
              </div>
            </div>
          </div>
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <button
              class="btn btn-danger btn-sm d-block w-100 mb-2 mt-2"
              type="button"
              (click)="selectEvent(testEvent)">
              <i class="bx bx-clipboard"></i>
              Sign up
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #noEvents>
    <div class="row justify-content-center">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">
        <div class="alert alert-info" role="alert">
          <div *ngIf="searchQuery; else noEventFoundMessage">
            Sorry, no events found for {{ searchQuery }}. Please send us a note if you would like us to come to your gym.
          </div>
          <ng-template #noEventFoundMessage>
            <i class="bx bxs-info-circle"></i> No events available at the moment.
          </ng-template>
        </div>

        <div class="d-flex justify-content-center">
          <a *ngIf="searchQuery" 
            class="mt-2 btn btn-outline-primary" 
            href="https://www.appliedbiox.com/mobile-fitness-solutions/" 
            target="_blank" 
            rel="noopener">
            Bring AppliedBioX to your gym
          </a>
        </div>
      </div>
    </div>
  </ng-template>
</div>
