<div class="container mt-5 text-center">
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card shadow-lg">
        <div class="card-body py-5">
          <h1 class="text-danger display-4">
            <i class="bi bi-x-circle-fill"></i> License Error
          </h1>
          <p class="lead mt-3">
            Your license is invalid, expired, or missing. Please contact support or update your license to regain
            access.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
