/* eslint-disable prefer-const */
import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
  FormArray,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  ClubI,
  CreateTestEventI,
  OperatorI,
  ProductI,
  RegionsI,
  ServicesI,
  TenantUsersI,
  TruckI,
} from '@bodyanalytics/data-models-ui';
import { ManageTestingEventService } from '@bodyanalytics/front/admin/manage-events/domain';
import {
  BodyanalyticsBaseComponent,
  eventStatus,
  eventTimes,
  MaterialModule,
  setupTestEventDateDefault,
  signupInterval,
} from '@bodyanalytics/ui-core';
import { formatISO, parseISO, setHours, setMilliseconds, setMinutes, setSeconds } from 'date-fns';

@Component({
  selector: 'bodyanalytics-event-create-form',
  templateUrl: './event-create-form.component.html',
  styleUrls: ['./event-create-form.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, NgClass, NgIf, NgFor, MaterialModule],
})
export class EventCreateFormComponent extends BodyanalyticsBaseComponent implements OnInit {
  @Output() submitFormEvent = new EventEmitter<any>();
  @Output() productsSelected = new EventEmitter<any>();
  @Input() item!: CreateTestEventI | null;
  formsubmit = false;
  isBreakTimeSelected = false;
  // private dateFormat = 'MM/DD/YYYY';
  public operators: OperatorI[] = [];
  public products: ProductI[] = [];
  public services: ServicesI[] = [];

  public regions: RegionsI[] = [];
  public trucks: TruckI[] = [];
  public eventLocation: ClubI[] = [];
  public availableServices: ServicesI[] = [];
  public createdByUsers: TenantUsersI[] = [];
  public isDisplaySlotsForServices = false;

  signupIntervalJson = [...signupInterval];

  eventStatusJson = [...eventStatus];

  eventTimesJson = [...eventTimes];

  defaultEventData = [...setupTestEventDateDefault];

  timeOptions = [] as string[];

  public testingEventDetailsForm = new FormGroup({
    testEventName: new FormControl('', [Validators.required]),
    testEventDescription: new FormControl('', [Validators.required]),
    testEventDate: new FormControl('', [Validators.required]),
    startTime: new FormControl('', [Validators.required]),
    endTime: new FormControl('', [Validators.required]),
    signupInterval: new FormControl('', [Validators.required]),
    status: new FormControl('', [Validators.required]),
    operators: new FormControl('', [Validators.required]),
    createdByUser: new FormControl('', [Validators.required]),
    trucks: new FormControl('', [Validators.required]),
    testEventAtClubId: new FormControl('', [Validators.required]),
    eventImage: new FormControl('', [Validators.required]),
    slug: new FormControl('', [Validators.required]),
    imageUrl: new FormControl('', [Validators.required]),
    productsArray: this.fb.array([], [Validators.required]),
    servicesArray: this.fb.array([], [Validators.required]),
    breakStartTime: new FormControl(''),
    breakEndTime: new FormControl(''),
    // note: new FormControl('', [Validators.required]),
    regionId: new FormControl('', [Validators.required]),
    // status: new FormControl('', [Validators.required]),
    isAllowedSignupOnline: new FormControl(true, [Validators.required]),
    isPaymentRequired: new FormControl(true, [Validators.required]),
    isTestingEventPrivate: new FormControl(false, [Validators.required]),
    isDisplayEventName: new FormControl(false, [Validators.required]),
    isDisplayEventDescription: new FormControl(false, [Validators.required]),
  });

  constructor(
    private fb: FormBuilder,
    private setupTestingService: ManageTestingEventService, // private modalService: NgbModal
  ) {
    super();

    this.timeOptions = this.generateTimeOptions() as string[];
  }

  public get imageUrl(): AbstractControl {
    return this.testingEventDetailsForm.get('imageUrl') as AbstractControl;
  }

  public get testEventDescriptionSelected(): AbstractControl {
    return this.testingEventDetailsForm.get('testEventDescription') as AbstractControl;
  }
  public get testEventStatusSelected(): AbstractControl {
    return this.testingEventDetailsForm.get('status') as AbstractControl;
  }

  public get testEventNameSelected(): AbstractControl {
    return this.testingEventDetailsForm.get('testEventName') as AbstractControl;
  }

  public get slugSelected(): AbstractControl {
    return this.testingEventDetailsForm.get('slug') as AbstractControl;
  }

  public get testEventDateSelected(): AbstractControl {
    return this.testingEventDetailsForm.get('testEventDate') as AbstractControl;
  }

  public get signupIntervalSelected(): AbstractControl {
    return this.testingEventDetailsForm.get('signupInterval') as AbstractControl;
  }

  public get startTimeSelected(): AbstractControl {
    return this.testingEventDetailsForm.get('startTime') as AbstractControl;
  }

  public get endTimeSelected(): AbstractControl {
    return this.testingEventDetailsForm.get('endTime') as AbstractControl;
  }

  public get operatorSelected(): AbstractControl {
    return this.testingEventDetailsForm.get('operators') as AbstractControl;
  }

  // public get serviceSelected(): AbstractControl {
  //   return this.testingEventDetailsForm.get('servicesId') as AbstractControl;
  // }

  // public get productSelected(): AbstractControl {
  //   return this.testingEventDetailsForm.get('products') as AbstractControl;
  // }
  public get truckSelected(): AbstractControl {
    return this.testingEventDetailsForm.get('trucks') as AbstractControl;
  }

  public get clubSelected(): AbstractControl {
    return this.testingEventDetailsForm.get('testEventAtClubId') as AbstractControl;
  }
  // public get eventStatusSelected(): AbstractControl {
  //   return this.testingEventDetailsForm.get('eventStatus') as AbstractControl;
  // }
  public get regionSelected(): AbstractControl {
    return this.testingEventDetailsForm.get('regionId') as AbstractControl;
  }

  ngOnInit(): void {
    this.operators = [];
    this.eventLocation = [];
    this.trucks = [];
    // this.services = [];
    this.products = [];
    this.regions = [];

    this.subscriptions.barrel = this.setupTestingService.getOperatorsList().subscribe(response => {
      this.operators = response;
    });
    this.subscriptions.barrel = this.setupTestingService.getTruckList().subscribe(response => {
      this.trucks = response;
    });

    this.subscriptions.barrel = this.setupTestingService.getClubsList().subscribe(response => {
      this.eventLocation = response;
    });

    this.subscriptions.barrel = this.setupTestingService.getAllRegions().subscribe(response => {
      this.regions = response;
    });

    this.subscriptions.barrel = this.setupTestingService.getAllProducts().subscribe(response => {
      this.products = response;
    });
    const tenantId = localStorage.getItem('tenantId')!;
    this.subscriptions.barrel = this.setupTestingService.getAllTenants(tenantId).subscribe(response => {
      this.createdByUsers = response;
    });

    this.setupConditionalValidation();
    this.operatorSelected.patchValue(this.defaultEventData[0].operatorId);
    // this.eventStatusSelected.patchValue(this.defaultEventData[0].eventStatusId);
    //  this.serviceSelected.patchValue(this.defaultEventData[0].serviceId);
    /// this.productSelected.patchValue(this.defaultEventData[0].productId);
    this.clubSelected.patchValue(this.defaultEventData[0].clubId);
    this.truckSelected.patchValue(this.defaultEventData[0].truckId);
    this.startTimeSelected.patchValue(this.defaultEventData[0].startTime);
    this.endTimeSelected.patchValue(this.defaultEventData[0].endTime);
    this.signupIntervalSelected.patchValue(this.defaultEventData[0].signupInterval);
    this.regionSelected.patchValue(this.defaultEventData[0].regionId);
    this.slugSelected.patchValue(this.defaultEventData[0].slug);
    this.imageUrl.patchValue(this.defaultEventData[0].imageUrl);
    this.testEventStatusSelected.patchValue(this.defaultEventData[0].status);
    this.testEventNameSelected.patchValue(this.defaultEventData[0].eventName);
    this.testEventDescriptionSelected.patchValue(this.defaultEventData[0].eventDescription);
  }
  get serviceValues() {
    return this.services.filter(x => x.isChecked).map(x => x.id);
  }

  showBreakTime() {
    this.isBreakTimeSelected = true;
  }
  hideBreakTime() {
    this.isBreakTimeSelected = false;
  }

  showEventName() {
    this.isBreakTimeSelected = true;
  }
  hideEventName() {
    this.isBreakTimeSelected = false;
  }

  onCheckboxProductChange($event: any) {
    const checkArray: FormArray = this.testingEventDetailsForm.get('productsArray') as FormArray;

    console.log('$event:', $event.target.value);
    if ($event.target.checked) {
      checkArray.push(new FormControl($event.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: any) => {
        if (item.value == $event.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  onCheckboxServiceChange($event: any) {
    const checkArray: FormArray = this.testingEventDetailsForm.get('servicesArray') as FormArray;

    console.log('$event:', $event.target.value);
    if ($event.target.checked) {
      checkArray.push(new FormControl($event.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: any) => {
        if (item.value == $event.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  updateTestEventDetails(): void {
    const formValues = this.testingEventDetailsForm.value;
    // if (this.testingEventDetailsForm.valid) {
    let testEventName = formValues.testEventName;
    let testEventDescription = formValues.testEventDescription;

    // Check if isDisplayEventName is true and set testEventName to the selected club's name
    if (!formValues.isDisplayEventName) {
      const selectedClub = this.eventLocation.find(club => club.id === formValues.testEventAtClubId);
      if (selectedClub) {
        testEventName = selectedClub.clubName;
      }
    }
    if (!formValues.isDisplayEventDescription) {
      testEventDescription = `Join us for a unique opportunity to take charge of your health and fitness with AppliedBioX at Hotworx Frisco. Whether you're an athlete looking to optimize your performance or someone on a fitness journey seeking to understand your body composition, this event is for you.`;
    }

    console.log('formValues.testEventDate (new)1: ', formValues.testEventDate!);
    // const format = 'MM/dd/yyyy'; // Example format, update based on your case
    // const timeZone = 'UTC'; // Use your desired time zone if needed

    // const testEventDateInput = this.testingEventDetailsForm.value.testEventDate;

    // // Parse the input date string to a JavaScript Date object
    // const parsedDate = parse(testEventDateInput!, format, new Date());

    // // Convert to UTC time if necessary (optional)
    // const utcDate = utcToZonedTime(parsedDate, timeZone);
    const formDate = this.testingEventDetailsForm.value.testEventDate!; // Replace with your input value
    const targetTimeInUTC = 6; // Hours to adjust for UTC offset

    // Parse the input date (assumes 'YYYY-MM-DD' format)
    const parsedDate = parseISO(formDate);

    // Adjust the time to 06:00:00 (UTC)
    const adjustedDate = setMilliseconds(setSeconds(setMinutes(setHours(parsedDate, targetTimeInUTC), 0), 0), 0);

    // Convert to ISO 8601 string
    const testEventDate = formatISO(adjustedDate);
    console.log('formValues.utcDate (new) 2: ', testEventDate);

    // const testEventDate = formatISO(parse(utcDate, format, new Date()));
    // Format to ISO 8601 string with UTC
    // const testEventDate = formatISO(utcDate);
    this.submitFormEvent.emit({
      testEventDescription: testEventDescription,
      testEventName: testEventName,
      assignedOperatorId: formValues.operators,
      assignedTruckId: formValues.trucks,
      testEventAtClubId: formValues.testEventAtClubId,
      testEventDate: testEventDate,
      productId: formValues.productsArray,
      serviceId: formValues.servicesArray,
      breakStartTime: formValues.breakStartTime,
      breakEndTime: formValues.breakEndTime,
      // discountsId: formValues.services,
      signupSlug: formValues.slug,
      imageUrl: formValues.imageUrl,
      startTime: formValues.startTime,
      endTime: formValues.endTime,
      regionId: formValues.regionId,
      status: formValues.status,
      createdByUserId: formValues.createdByUser,
      signupInterval: formValues.signupInterval,
      isAllowedSignupOnline: formValues.isAllowedSignupOnline,
      isPaymentRequired: formValues.isPaymentRequired,
      isTestingEventPrivate: formValues.isTestingEventPrivate,
    });
    // }
  }

  /**
   * Returns form
   */
  get form() {
    return this.testingEventDetailsForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
  formSubmit() {
    this.formsubmit = true;
  }
  generateTimeOptions() {
    let options = [];

    for (let hour = 8; hour <= 22; hour++) {
      // Convert to 12-hour format and determine AM/PM
      const meridian = hour < 12 ? 'AM' : 'PM';
      const hour12 = hour > 12 ? hour - 12 : hour;
      const hourFormatted = hour12 < 10 ? `0${hour12}` : `${hour12}`;

      // Full hour with AM/PM
      options.push(`${hourFormatted}:00 ${meridian}`);

      if (hour !== 22) {
        // Adding half-hour options
        options.push(`${hourFormatted}:30 ${meridian}`);
      }
    }
    return options;
    // let options = [];
    // for (let hour = 8; hour <= 22; hour++) {
    //   let hourFormatted = hour < 10 ? `0${hour}` : `${hour}`;
    //   options.push(`${hourFormatted}:00`); // Full hour

    //   // let time = hour < 10 ? `0${hour}:00` : `${hour}:00`; // 24-hour format
    //   // options.push(time);
    //   if (hour !== 22) { // Adding half-hour options
    //     // options.push(`${hour}:30`);
    //     options.push(`${hourFormatted}:30`);

    //   }
    // }
    // return options;
  }

  onTimeSelect(controlName: string) {
    const dateValue = this.testingEventDetailsForm.get('testEventDate')?.value;
    const time = this.testingEventDetailsForm.get(controlName)?.value;

    console.log('Selected Time for', controlName, ':', time);
    // eslint-disable-next-line no-unsafe-optional-chaining
    let dateEvent = dateValue?.split('T')[0]; // Extracting the date part
    console.log('Selected Date for', controlName, ':', dateEvent);

    const dateTime = `${time?.split('T')[0]}`;
    // let dateTimeTemp = `${dateTime}T${time?.split('^')[1]}`;

    // console.log('Updated DateTime for', controlName, ':', dateTimeTemp);

    // this.testingEventDetailsForm.get(controlName)?.setValue(dateTimeTemp);
    //console.log('Selected DateTime for', controlName, ':', dateTime); // For debugging
  }
  formatTimeForFormControl(time: string): string {
    // Example: Append a test date to the time
    const testDate = this.testingEventDetailsForm.get('testEventDate')?.value;
    return `${time}`;
  }
  setupConditionalValidation(): void {
    this.testingEventDetailsForm.get('isDisplayEventName')?.valueChanges.subscribe((isDisplay: boolean | null) => {
      const testEventNameControl = this.testingEventDetailsForm.get('testEventName');
      if (isDisplay) {
        testEventNameControl?.setValidators(Validators.required);
      } else {
        testEventNameControl?.clearValidators();
      }
      testEventNameControl?.updateValueAndValidity();
    });

    this.testingEventDetailsForm
      .get('isDisplayEventDescription')
      ?.valueChanges.subscribe((isDisplay: boolean | null) => {
        const testEventDescriptionControl = this.testingEventDetailsForm.get('testEventDescription');
        if (isDisplay) {
          testEventDescriptionControl?.setValidators(Validators.required);
        } else {
          testEventDescriptionControl?.clearValidators();
        }
        testEventDescriptionControl?.updateValueAndValidity();
      });
  }
}
