import { Component, ChangeDetectionStrategy, input, signal } from '@angular/core';
import { BodyanalyticsBaseComponent, ContentEnum, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { ManageBodymeasurementsFormComponent } from '@bodyanalytics/front/admin/manage-bodymeasurements/ui';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Location } from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bodyanalytics-manage-bodymeasurements-details',
  standalone: true,
  templateUrl: './manage-bodymeasurements-details.component.html',
  styleUrls: ['./manage-bodymeasurements-details.component.scss'],
  imports: [ManageBodymeasurementsFormComponent],
})
export class ManageBodymeasurementsDetailsComponent extends BodyanalyticsBaseComponent {
  protected contentEnum = ContentEnum;
  protected routerUrlsEnum = RouterUrlsEnum;

  id = input<string>();
  protected data = signal<any | undefined>(undefined);

  // protected data = toSignal(
  //   toObservable(this.id).pipe(
  //     filter(Boolean),
  //     switchMap(id => this.service.getBodyMeasurement(id)),
  //   ),
  // );

  constructor(private $gaService: GoogleAnalyticsService,    protected location: Location) {
    super();
    this.$gaService.pageView('manage-bodymeasurements/detail/' + this.id, 'Bodymeasurements Details');
  }
  public goBack() {
    this.location.back();
  }
  public processBodyMeasurementUpdate(signupDetails: any): void {
    // const id = this.route.snapshot.paramMap.get('id') as string;
    // this.subscriptions.barrel = this.service
    //   .updateBodyMeasurement(id, signupDetails)
    //   .pipe(
    //     tap(() => {
    //       console.log('here: processBodyMeasurementUpdate');
    //       // this.modalService.dismissAll();
    //       // this.submitted = true;
    //     }),
    //     catchError((error: any) => {
    //       // doc.file.hasError = true;
    //       // doc.file.error = error.apiErrorMsg ? error.apiErrorMsg : this.genericErrorMessage;
    //       // doc.file.uploading = false;
    //       // doc.file.uploaded = false;
    //       return of(error);
    //     }),
    //   )
    //   .subscribe();
  }
}
