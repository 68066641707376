import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { LicenseService } from '@bodyanalytics/front/admin/license/domain';
import { RouterUrlsEnum } from '@bodyanalytics/ui-core';

@Component({
  selector: 'lib-license-verify',
  standalone: true,
  imports: [NgIf],
  templateUrl: './license-verify.component.html',
  styleUrl: './license-verify.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LicenseVerifyComponent {
  isValid = false;
  protected routes = RouterUrlsEnum;

  constructor(
    private router: Router,
    private licenseService: LicenseService,
  ) {}

  checkLicense() {
    const licenseKey = localStorage.getItem('licenseKey'); // Retrieve licenseKey securely
    if (!licenseKey) {
      alert('No license key found!');
      return;
    }

    this.licenseService.validateLicense(licenseKey).subscribe(response => {
      this.isValid = response.isValid;
    });
  }

  generateLicense() {
    this.router.navigate([`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_LICENSE_CREATE}`]);
  }
}
