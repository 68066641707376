import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, map, catchError } from 'rxjs';
import { LicenseService } from './license.service';

@Injectable({
  providedIn: 'root',
})
export class LicenseGuard implements CanActivate {
  licenseKey: string | null = null;
  tenantId: string | null = null;
  constructor(
    private licenseService: LicenseService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.licenseKey = localStorage.getItem('licenseKey'); // Assuming licenseKey is stored in localStorage
    this.tenantId = localStorage.getItem('tenantId'); // Assuming licenseKey is stored in localStorage

    if (!this.licenseKey && !this.tenantId) {
      this.licenseService.getLicenseFromServer().subscribe({
        next: response => {
          this.licenseKey = response.licenseKey;
          this.tenantId = response.tenantId;
          localStorage.setItem('licenseKey', this.licenseKey);
          localStorage.setItem('tenantId', this.tenantId); // Optionally save it locally
          this.router.navigate(['/auth/admin-dashboard']); // Redirect to login if licenseKey is missing
          return true;
        },
        error: err => {
          console.error('Error retrieving license:', err);
        },
      });
      this.router.navigate(['/auth/license/error']); // Redirect to login if licenseKey is missing
      return false;
    }



    return this.licenseService.validateLicense(this.licenseKey!).pipe(
      map(response => {
        if (response.isValid) {
          return true;
        } else {
          this.router.navigate(['/auth/license/error']); // Redirect to error page
          return false;
        }
      }),
      catchError(err => {
        console.error('License validation failed:', err);
        this.router.navigate(['/auth/license/error']); // Redirect to error page
        return [false];
      }),
    );
  }
}
