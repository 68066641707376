import { NgClass, NgIf } from '@angular/common';
import { Location } from '@angular/common';

import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { toSignal, toObservable } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminRegisterStore, AdminRegisterService } from '@bodyanalytics/front/admin/registration/domain';
import { CreateTenantFormComponent } from '@bodyanalytics/front/admin/registration/ui';
import {
  UiCoreModule,
  MaterialModule,
  BodyanalyticsBaseComponent,
  ContentEnum,
  RouterUrlsEnum,
} from '@bodyanalytics/ui-core';

import { filter, switchMap } from 'rxjs';

@Component({
  selector: 'lib-create-tenant',
  standalone: true,
  imports: [ReactiveFormsModule, NgClass, NgIf, UiCoreModule, MaterialModule, CreateTenantFormComponent],
  templateUrl: './create-tenant.component.html',
  styleUrl: './create-tenant.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateTenantComponent extends BodyanalyticsBaseComponent {
  protected contentEnum = ContentEnum;
  protected routerUrlsEnum = RouterUrlsEnum;
  protected store = inject(AdminRegisterStore);
  id = input<string>();
  protected plans = this.store.plansEntities;
  protected data = toSignal(
    toObservable(this.id).pipe(
      filter(Boolean),
      switchMap(id => this.adminRegisterService.getTenant(id)),
    ),
  );
  protected loadingSetupSession = false;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private adminRegisterService: AdminRegisterService,
  ) {
    super();
  }
  public goBack() {
    this.location.back();
  }

  public isCreateTenant() {
    this.router.navigate([`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_CUSTOMERS}`]);
  }

  public isCreateOrg() {
    this.router.navigate([`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_TENANTS_CREATE_ORG}`]);
  }

  public isCreateUser() {
    this.router.navigate([`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_TENANTS_CREATE_USER}`]);
  }
  public isLinkAuth() {
    this.router.navigate([`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_TENANTS_LINK_AUTH}`]);
  }
  public isLinkPay() {
    this.router.navigate([`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_TENANTS_LINK_PAY}`]);
  }

  public processTenantUpdate(signupDetails: any): void {
    this.isCreateOrg();
  }
}
