import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { OrderConfirmationI } from '@bodyanalytics/data-models-ui';
import { CheckoutService } from '../../services/checkout.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { format, utcToZonedTime } from 'date-fns-tz';

@Component({
  selector: 'bodyanalytics-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss'],
})
export class OrderConfirmationComponent extends BodyanalyticsBaseComponent implements OnInit {
  public orderConfirmationData!: OrderConfirmationI;
  formattedTime!: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private checkoutService: CheckoutService,
    private $gaService: GoogleAnalyticsService,
  ) {
    super();
  }

  ngOnInit(): void {
    // this.signupService.removeUserData();
    this.checkoutService.clearSession();
    if (this.activatedRoute && this.activatedRoute.data) {
      this.subscriptions.barrel = this.activatedRoute.data.subscribe(({ orderData }) => {
        if (orderData) {
          this.orderConfirmationData = orderData;

          this.$gaService.pageView(
            'order-confirmation/' + this.orderConfirmationData.id,
            'Order Confirmation',
          );

          if (this.orderConfirmationData.booking.slot.slotTime) {
            const timeZone = 'America/Chicago'; // Central Time

            // Convert the test event date to Central Time (CT)
            const eventDateCT = utcToZonedTime(this.orderConfirmationData.booking.slot.slotTime!, timeZone);

            // Format the date for display
            this.formattedTime = format(eventDateCT, 'h:mm a');
          }
        }
      });
    }
  }

  goToDashboard() {
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_DASHBOARD}`);
  }
}
