import { HttpHeaders, HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENV } from '@bodyanalytics/app-config';
import { UserDetailsI } from '@bodyanalytics/data-models-ui';
import { ApiLocalRoutesEnum, SharedLogService, SharedErrorService } from '@bodyanalytics/ui-core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminUserService {

  private env = inject(ENV);

  private tenantUrl = `${ApiLocalRoutesEnum.API_SUFFIX_TENANT_USER}`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    private sharedLogService: SharedLogService,
    private sharedErrorService: SharedErrorService,
  ) {}

  getUserDetails(): Observable<UserDetailsI> {
    const tenantId = localStorage.getItem('tenantId');
    const url = `${this.env.api}/${this.tenantUrl}/${tenantId}`;

    return this.http.get<UserDetailsI>(`${url}`);
  }
}
