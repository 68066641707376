import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TestingEventI } from '@bodyanalytics/data-models-ui';

@Component({
  selector: 'bodyanalytics-testevents-list',
  templateUrl: './testevents-list.component.html',
  styleUrls: ['./testevents-list.component.scss'],
})
export class TesteventsListComponent {
  @Input() testEvents: TestingEventI[] | null = [];
  @Input() readonly: boolean | null = false;
  @Input() searchQuery: string = '';
  @Output() select = new EventEmitter();
  @Output() delete = new EventEmitter();

  public selectEvent(testEvent: TestingEventI) {
    this.select.emit(testEvent);
  }
}
