import { LicenseService } from '@bodyanalytics/front/admin/license/domain';
import { CreateLicenseComponent } from './create-license/create-license.component';
import { LicenseErrorComponent } from './license-error/license-error.component';
import { LicenseVerifyComponent } from './license-verify/license-verify.component';
import { Route } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { LicenseShellComponent } from './license-shell/license-shell.component';

export const manageLicenseUiRoutes: Route = {
  path: 'auth/license',
  component: LicenseShellComponent,
  canActivate: [AuthGuard],
  providers: [LicenseService],
  children: [
    { path: '', pathMatch: 'full', redirectTo: 'check' },
    { path: 'check', component: LicenseVerifyComponent, pathMatch: 'full' },
    { path: 'create', component: CreateLicenseComponent, pathMatch: 'full' },
    { path: 'error', component: LicenseErrorComponent, pathMatch: 'full' },
  ],
};
