import { Component, OnInit, output, input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TestingEventI, OperatorI, TruckI, ClubI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, eventTimes, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { tap, catchError, of } from 'rxjs';
import { Router } from '@angular/router';
import { ManageTestingEventService } from '@bodyanalytics/front/admin/manage-events/domain';

@Component({
  selector: 'bodyanalytics-event-break-form',
  templateUrl: './event-break-form.component.html',
})
export class EventBreakFormComponent extends BodyanalyticsBaseComponent implements OnInit {
  item = input.required<TestingEventI>();
  breaksAssigned = output<any>();

  protected EVENT_ID = '';
  protected CLUB_ID = '';
  protected formsubmit = false;
  protected operators: OperatorI[] = [];
  protected trucks: TruckI[] = [];
  protected eventLocation: ClubI[] = [];
  protected eventTimesJson = [...eventTimes];
  protected testingEventDetailsForm = new FormGroup({
    startTime: new FormControl('', [Validators.required]),
    endTime: new FormControl('', [Validators.required]),

    signupAvailabilityId: new FormControl('', [Validators.required]),
  });

  constructor(private router: Router, private manageTestingEventService: ManageTestingEventService) {
    super();
  }

  ngOnInit(): void {
    this.operators = [];
    this.eventLocation = [];
    this.trucks = [];

    this.subscriptions.barrel = this.manageTestingEventService.getOperatorsList().subscribe(response => {
      this.operators = response;
    });
    this.subscriptions.barrel = this.manageTestingEventService.getTruckList().subscribe(response => {
      this.trucks = response;
    });

    this.subscriptions.barrel = this.manageTestingEventService.getClubsList().subscribe(response => {
      this.eventLocation = response;
    });
    this.getTestingEvent();
    // this.EVENT_ID = this.item().testingevent_id as string;
    // this.CLUB_ID = this.item().club_testingevent_id as string;
  }

  public getTestingEvent(): void {
    // if (this.testingEventDetailsForm.valid) {
    // if (this.item as TestingEventI) {
    // const isoStartTime = this.convertTimeToISOString(this.item.startTime);
    // const isoEndTime = this.convertTimeToISOString(this.item.endTime);
    // const eventDate = new Date(this.item.testEventDate).toISOString().split('T')[0];
    this.EVENT_ID = this.item().id as string;
    this.CLUB_ID = this.item().testEventAtClub.id as string;
    this.testingEventDetailsForm.patchValue({
      startTime: this.item().startTime,
      endTime: this.item().endTime,
    });
    // this.testEventDateSelected.patchValue(this.item().testEventDate as string);
    // this.startTimeSelected.patchValue('8:00');
    // this.endTimeSelected.patchValue('14:00');
    // this.signupIntervalSelected.patchValue('FIFTEEN_MIN');
    // }
  }

  public updateTestingEvent(): void {
   
  }

  openModal(content: any) {
    // this.modalService.open(content, { backdropClass: 'light-blue-backdrop', size: 'lg' });
  }

  convertTimeToISOString(time: string): string {
    const dateToday: Date = new Date();
    // Split the hours and minutes
    const [hours, minutes] = time.split(':');

    // Set the hours and minutes to the specified date
    dateToday.setHours(Number(hours), Number(minutes), 0, 0);

    // Return the ISO string
    const testDate = new Date(dateToday);

    return testDate.toISOString();
  }

  protected goToSignupPage() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/detail/${this.EVENT_ID}`);
  }
  protected duplicateEvent() {
    this.subscriptions.barrel = this.manageTestingEventService
      .duplicateTestingEvent(`${this.EVENT_ID}`, this.item())
      .pipe(
        tap(() => {
          console.log(`duplicateTestingEvent - ${this.EVENT_ID}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
  protected sendEmailReminder() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EMAILS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
  }
  protected sendSmsReminder() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
  }
  protected cancelEvent() {
    this.subscriptions.barrel = this.manageTestingEventService
      .deleteTestingEvent(`${this.EVENT_ID}`)
      .pipe(
        tap(() => {
          console.log(`cancelEvent - ${this.EVENT_ID}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
  protected viewClubDetails() {
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_CLUBS_DETAILS}/${this.CLUB_ID}`);
  }
  protected showSignupList() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
  }
  closeModal() {
    console.log('modal');
    //   this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BFTESTS_DETAIL}/auth/manage-events`);
    // this.modalService.dismissAll();
  }

  /**
   * Returns form
   */
  get form() {
    return this.testingEventDetailsForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
  formSubmit() {
    this.formsubmit = true;
  }

  // public get testEventDescriptionSelected(): AbstractControl {
  //   return this.testingEventDetailsForm.get('testEventDescription') as AbstractControl;
  // }
  // public get testEventStatusSelected(): AbstractControl {
  //   return this.testingEventDetailsForm.get('status') as AbstractControl;
  // }

  // // public get testEventNameSelected(): AbstractControl {
  // //   return this.testingEventDetailsForm.get('testEventName') as AbstractControl;
  // // }

  // public get testEventDateSelected(): AbstractControl {
  //   return this.testingEventDetailsForm.get('testEventDate') as AbstractControl;
  // }

  // public get signupIntervalSelected(): AbstractControl {
  //   return this.testingEventDetailsForm.get('signupInterval') as AbstractControl;
  // }

  // public get startTimeSelected(): AbstractControl {
  //   return this.testingEventDetailsForm.get('startTime') as AbstractControl;
  // }

  // public get endTimeSelected(): AbstractControl {
  //   return this.testingEventDetailsForm.get('endTime') as AbstractControl;
  // }

  // public get operatorSelected(): AbstractControl {
  //   return this.testingEventDetailsForm.get('assignedOperatorId') as AbstractControl;
  // }

  // public get truckSelected(): AbstractControl {
  //   return this.testingEventDetailsForm.get('trucks') as AbstractControl;
  // }

  // public get clubSelected(): AbstractControl {
  //   return this.testingEventDetailsForm.get('testEventAtClubId') as AbstractControl;
  // }
}
