import { Route } from '@angular/router';
 import { AuthGuard } from '@auth0/auth0-angular';

import { provideTestingEvents } from '@bodyanalytics/front/admin/manage-events/domain';
import { CompleteEventComponent } from './complete-event-created/review-event.component';
import { CreateEventComponent } from './create-event/create-event.component';
import { ManageEventsDashboardComponent } from './dashboard/dashboard.component';
import { ManageEventsDetailsComponent } from './event-details/event-details.component';
import { ManageEventsComponent } from './manage-events/manage-events.component';
import { ReviewEventComponent } from './review-event/review-event.component';
import { EventListComponent } from './event-list/event-list.component';

export const manageEventsUiRoutes: Route[] = [
  {
    path: 'auth/manage-events',
    component: ManageEventsComponent,
    canActivate: [AuthGuard],
    providers: [provideTestingEvents()],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      {
        path: 'create-event',
        component: CreateEventComponent,
        pathMatch: 'full',
      },
      {
        path: 'review-event/:id',
        component: ReviewEventComponent,
        pathMatch: 'full',
      },
      {
        path: 'create-complete/:id',
        component: CompleteEventComponent,
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: ManageEventsDashboardComponent,
        pathMatch: 'full',
      },
      {
        path: 'detail/:id',
        component: ManageEventsDetailsComponent,
      },
      {
        path: 'list/:id',
        component: EventListComponent,
      },
    ],
  },
];
