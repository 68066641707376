<div class="container mt-5">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <h1 class="text-center mb-4 text-dark">Generate License</h1>
      <form [formGroup]="licenseForm" (ngSubmit)="onSubmit()" class="card p-4 shadow">
        <!-- User ID -->
        <div class="mb-3">
          <label for="userEmail" class="form-label">User Email</label>
          <input id="userEmail" formControlName="emailAddress" type="text" class="form-control"
            placeholder="Enter Email Address" />
          <div *ngIf="licenseForm.get('emailAddress')?.invalid && licenseForm.get('emailAddress')?.touched"
            class="text-danger mt-1">
            Email address is required.
          </div>
        </div>

        <!-- Validity Days -->
        <div class="mb-3">
          <label for="validityDays" class="form-label">Validity Days</label>
          <input id="validityDays" formControlName="validityDays" type="number" class="form-control"
            placeholder="Enter Validity Days" />
          <div *ngIf="licenseForm.get('validityDays')?.invalid && licenseForm.get('validityDays')?.touched"
            class="text-danger mt-1">
            Validity Days must be at least 1.
          </div>
        </div>

        <!-- Submit Button -->
        <div class="d-grid">
          <button type="submit" class="btn btn-primary" [disabled]="licenseForm.invalid">
            Generate License
          </button>
        </div>
      </form>

      <!-- License Key Display -->
      <div *ngIf="licenseKey" class="alert alert-success mt-4">
        <h4 class="alert-heading">License Generated</h4>
        <p>{{ licenseKey }}</p>
      </div>

      <!-- Error Message -->
      <div *ngIf="error" class="alert alert-danger mt-4">
        <p>{{ error }}</p>
      </div>
    </div>
  </div>
</div>
