import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LicenseService } from '@bodyanalytics/front/admin/license/domain';
import { BodyanalyticsBaseComponent } from '@bodyanalytics/ui-core';

@Component({
  selector: 'lib-create-license',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './create-license.component.html',
  styleUrl: './create-license.component.scss',
 })
export class CreateLicenseComponent extends BodyanalyticsBaseComponent {
  licenseForm: FormGroup;
  licenseKey: string | null = null;
  error: string | null = null;

  constructor(
    private fb: FormBuilder,
    private licenseService: LicenseService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
    this.licenseForm = this.fb.group({
      emailAddress: ['', [Validators.required]],
      validityDays: [30, [Validators.required, Validators.min(1)]],
    });
  }

  onSubmit() {
    if (this.licenseForm.invalid) {
      return;
    }

    const { emailAddress, validityDays } = this.licenseForm.value;

    this.subscriptions.barrel = this.licenseService.generateLicense(emailAddress, validityDays).subscribe({
      next: response => {
        this.licenseKey = response.licenseKey;
        this.error = null;
        this.cdr.detectChanges();
      },
      error: err => {
        this.error = 'Failed to generate license. Please try again.';
        this.licenseKey = null;
        this.cdr.detectChanges();
      },
    });
  }
}
