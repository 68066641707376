import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { catchError, Observable, tap } from 'rxjs';
import { ApiLocalRoutesEnum, SharedErrorService, SharedLogService } from '@bodyanalytics/ui-core';
import {
  ClubI,
  RegionsI,
  StateI,
} from '@bodyanalytics/data-models-ui';
import { ENV } from '@bodyanalytics/app-config';

@Injectable()
export class ManageClubService {
  private env = inject(ENV);
  private clubsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_CLUBS}`;
  private clubDetailUrl = `${ApiLocalRoutesEnum.API_SUFFIX_CLUBS_DETAILS}`;
  private statesUrl = `${ApiLocalRoutesEnum.API_SUFFIX_REGIONS}/us-states`;
  private regionUrl = `${ApiLocalRoutesEnum.API_SUFFIX_REGIONS}`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(
    private http: HttpClient,
    private sharedLogService: SharedLogService,
    private sharedErrorService: SharedErrorService,
  ) {}

  getRegions(): Observable<RegionsI[]> {
    const url = `${this.env.api}/${this.regionUrl}`;
    return this.http.get<RegionsI[]>(url).pipe(
      tap(_ => console.log('fetched regions')),
      catchError(this.sharedErrorService.handleError<RegionsI[]>('getRegionsList', [])),
    );
  }

  getStatesList(): Observable<StateI[]> {
    const url = `${this.env.api}/${this.statesUrl}`;
    return this.http.get<StateI[]>(url).pipe(
      tap(_ => console.log('fetched clubs')),
      catchError(this.sharedErrorService.handleError<StateI[]>('getStatesList', [])),
    );
  }

  getClub(id: string): Observable<ClubI> {
    const url = `${this.env.api}/${this.clubDetailUrl}/${id}`;
    return this.http.get<ClubI>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched club id=${id}`)),
      catchError(this.sharedErrorService.handleError<ClubI>(`getClub id=${id}`)),
    );
  }

  updateClub(id: string, testingEvent: ClubI): Observable<any> {
    const url = `${this.env.api}/${this.clubDetailUrl}/${id}`;
    return this.http.patch(url, testingEvent, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`updated club id=${id}`)),
      catchError(this.sharedErrorService.handleError<any>('updateClub')),
    );
  }
  deleteClub(id: string): Observable<ClubI> {
    const url = `${this.env.api}/${this.clubDetailUrl}/${id}`;

    return this.http.delete<ClubI>(url, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`deleted club id=${id}`)),
      catchError(this.sharedErrorService.handleError<ClubI>('deleteClub')),
    );
  }

  load(): Observable<ClubI[]> {
    const url = `${this.env.api}/${this.clubsUrl}`;
    return this.http.get<ClubI[]>(url).pipe(
      tap(_ => console.log('fetched clubs')),
      catchError(this.sharedErrorService.handleError<ClubI[]>('getClubs', [])),
    );
  }

  addClub(testingEvent: ClubI): Observable<ClubI> {
    const url = `${this.env.api}/${this.clubsUrl}`;

    return this.http.post<ClubI>(url, testingEvent, this.httpOptions).pipe(
      tap((newClub: ClubI) => this.sharedLogService.log(`added club w/ id=${newClub.id}`)),
      catchError(this.sharedErrorService.handleError<ClubI>('addClub')),
    );
  }
}
