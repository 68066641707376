<section id="manage-bodymeasurements-details" class="dashboard-container dashboard">
  <div class="container">
    <div class="row">
      <div class="col-md-12 bg-white p-2">
        <div class="form w-100 pb-2">

          <div class="row">
            <div class="col-md-8 mx-auto">
              <div class="my-5">
                <h4 class="h5 text-dark">Create Tenant</h4>
                <div class="row">
                  <div class="col-md-12">
                    <p class="text-dark">
                      Your tenant environment is the foundation of your account. It’s where all your users, resources,
                      and data will live. Start by giving your tenant a name, assigning a unique ID, and defining its
                      basic settings. Don’t worry—you can always update these later.
                    </p>
                    <lib-create-tenant-form [plans]="plans()"
                      (submitFormEvent)="processTenantUpdate($event)"></lib-create-tenant-form>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
