<form [formGroup]="tenantForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-md-7">

      <div class="mb-3">
        <label for="name" class="form-label text-dark">Name</label>
        <input id="name" formControlName="name" type="text" class="form-control"
          [class.is-invalid]="tenantNameControl?.invalid && tenantNameControl?.touched" />

        <div *ngIf="tenantNameControl?.invalid && tenantNameControl?.touched" class="invalid-feedback">
          <div *ngIf="tenantNameControl?.errors?.['required']">
            Name is required.
          </div>

        </div>
      </div>
      <div class="mb-3">
        <label for="domain" class="form-label text-dark">Domain</label>
        <input id="domain" formControlName="domain" type="text" class="form-control"
          [class.is-invalid]="domainControl?.invalid && domainControl?.touched" />
        <div *ngIf="domainControl?.invalid && domainControl?.touched" class="invalid-feedback">
          <div *ngIf="domainControl?.errors?.['required']">
            Domain is required.
          </div>
          <div *ngIf="domainControl?.errors?.['pattern']">
            Please enter a valid domain in the format: https://www.domain.com.
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label for="plan" class="form-label text-dark">Plan</label>

        <select id="plan" formControlName="plan" class="form-control" [disabled]=" plans().length === 0">
          <option value="" disabled>Select a plan</option>
          <option *ngFor="let plan of plans()" [value]="plan.id">{{ plan.name }}</option>
        </select>

      </div>
      <button type="submit" class="btn btn-primary my-4">Create</button>
    </div>
  </div>
</form>
