<!-- <iframe src="https://app.acuityscheduling.com/schedule.php?owner=31680722" title="Schedule Appointment" width="100%" height="800" frameBorder="0"></iframe><script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script> -->

<div class="container">
  <div class="row">
    <div class="col-md-12 col-sm-12 bg-secondary bg-gradient mt-2">
      <div class="card mb-2 p-2 w-100 text-white bg-secondary bg-gradient border-0">
        <div class="card-body border-0">
          <h2 class="display-5 fw-bold lh-1 mb-3">Welcome, Admin!</h2>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <h4 class="card-title py-4">Dashboard</h4>

      <!-- <iframe src="https://app.acuityscheduling.com/schedule.php?owner=31680752" title="Schedule Appointment" width="100%" height="800" frameBorder="0"></iframe><script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script> -->
      <div class="card mb-3 border-0">
        <div class="card-body p-0">
          <div class="row d-none">
            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex">
                    <div class="flex-1 overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">Number of Sales</p>
                      <h4 class="mb-0">1452</h4>
                    </div>
                    <div class="text-primary ms-auto">
                      <i class="ri-stack-line font-size-24"></i>
                    </div>
                  </div>
                </div>

                <div class="card-body border-top py-3">
                  <div class="text-truncate">
                    <span class="badge bg-success-subtle text-success font-size-11"
                      ><i class="mdi mdi-menu-up"> </i> 2.4%
                    </span>
                    <span class="text-muted ms-2">From previous period</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex">
                    <div class="flex-1 overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">Sales Revenue</p>
                      <h4 class="mb-0">$ 38452</h4>
                    </div>
                    <div class="text-primary ms-auto">
                      <i class="ri-store-2-line font-size-24"></i>
                    </div>
                  </div>
                </div>
                <div class="card-body border-top py-3">
                  <div class="text-truncate">
                    <span class="badge bg-success-subtle text-success font-size-11"
                      ><i class="mdi mdi-menu-up"> </i> 2.4%
                    </span>
                    <span class="text-muted ms-2">From previous period</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex">
                    <div class="flex-1 overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">Average Price</p>
                      <h4 class="mb-0">$ 15.4</h4>
                    </div>
                    <div class="text-primary ms-auto">
                      <i class="ri-briefcase-4-line font-size-24"></i>
                    </div>
                  </div>
                </div>
                <div class="card-body border-top py-3">
                  <div class="text-truncate">
                    <span class="badge bg-success-subtle text-success font-size-11"
                      ><i class="mdi mdi-menu-up"> </i> 2.4%
                    </span>
                    <span class="text-muted ms-2">From previous period</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row mb-5 mt-5">
            <div class="col-md-6">

              <h4 class="card-title mb-3 py-3"><strong>Upcoming Events</strong></h4>

            </div>
            <div class="col-md-6">
              <h4 class="card-title mb-3 py-3"><strong>Top Gyms (Revenue Per Event)</strong></h4>

            </div>
          </div> -->
          <div class="row mt-3">
            <div class="col-md-12">
              <h4 class="card-title mb-3 py-3">Admin Options</h4>
              <div class="row my-3">
                <div class="col-md-4 col-sm-12">
                  <div class="list-group">
                    <button type="button" class="list-group-item list-group-item-action" (click)="isManageCustomers()">
                      Customers
                    </button>
                    <button type="button" class="list-group-item list-group-item-action" (click)="isManageClubs()">
                      Clubs
                    </button>
                    <!-- <button type="button" class="list-group-item list-group-item-action" (click)="isManageEmails()">
                      Emails
                    </button> -->
                    <button type="button" class="list-group-item list-group-item-action" (click)="isManageEvents()">
                      Events
                    </button>
                    <button type="button" class="list-group-item list-group-item-action" (click)="isManageBookings()">
                      Bookings
                    </button>
                    <!-- <button type="button" class="list-group-item list-group-item-action" (click)="isManageSignups()">
                      Signups
                    </button> -->
                    <!-- <button type="button" class="list-group-item list-group-item-action" (click)="isManageSms()">
                      SMS
                    </button> -->
                  </div>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div class="list-group">
                    <!-- <button type="button" class="list-group-item list-group-item-action" (click)="isManageContent()">
                      Content
                    </button> -->
                    <button
                      type="button"
                      class="list-group-item list-group-item-success list-group-item-action"
                      (click)="isManageCompany()">
                      Setup Company
                    </button>
                    <button type="button" class="list-group-item list-group-item-action" (click)="isManageDiscounts()">
                      Discounts
                    </button>
                    <button type="button" class="list-group-item list-group-item-action" (click)="isManageLicense()">
                      License
                    </button>
                    <!-- <button type="button" class="list-group-item list-group-item-action" (click)="isManageOperators()">
                      Operators
                    </button> -->
                    <!-- <button type="button" class="list-group-item list-group-item-action" (click)="isManagePayments()">
                      Payments
                    </button> -->
                    <button type="button" class="list-group-item list-group-item-action" (click)="isManageOrders()">
                      Orders
                    </button>

                    <!-- <button type="button" class="list-group-item list-group-item-action" (click)="isManageMealPlans()">
                       Meal Plans
                    </button> -->
                    <!-- <button type="button" class="list-group-item list-group-item-action" (click)="isManageCustomers()">
                       Reports
                    </button> -->
                  </div>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div class="list-group">
                    <!-- <button type="button" class="list-group-item list-group-item-action" (click)="isManageProducts()">
                      Products
                    </button> -->
                    <button type="button" class="list-group-item list-group-item-action" (click)="isManageRegions()">
                      Regions
                    </button>
                    <!-- <button type="button" class="list-group-item list-group-item-action" (click)="isManageTenants()">
                      Tenants
                    </button> -->
                    <!-- <button type="button" class="list-group-item list-group-item-action" (click)="isManageSettings()">
                      Settings
                    </button> -->
                    <button
                      [routerLink]="routes.AUTH_ADMIN_MANAGE_BFTESTS"
                      class="list-group-item list-group-item-action"
                      type="button">
                      Tests
                    </button>
                    <!-- <button type="button" class="list-group-item list-group-item-action" (click)="isManageUsers()">
                      Users
                    </button> -->

                    <!-- <button type="button" class="list-group-item list-group-item-action" (click)="isManageCustomers()">
                       Meal Plans
                    </button> -->
                    <!-- <button type="button" class="list-group-item list-group-item-action" (click)="isManageCustomers()">
                       Reports
                    </button> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row d-none">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Upcoming Events</h4>

          <div class="table-responsive">
            <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
              <div class="row">
                <div class="row">
                  <div class="col-sm-12">
                    <table
                      class="table table-centered datatable dt-responsive nowrap dataTable no-footer dtr-inline"
                      data-bs-page-length="5"
                      style="border-collapse: collapse; border-spacing: 0px; width: 100%"
                      id="DataTables_Table_0"
                      aria-describedby="DataTables_Table_0_info">
                      <thead class="table-light">
                        <tr>
                          <th
                            style="width: 29px"
                            class="sorting_disabled"
                            rowspan="1"
                            colspan="1"
                            aria-label="


                                      &amp;nbsp;

                              ">
                            <div class="form-check">
                              <input type="checkbox" class="form-check-input" id="ordercheck" />
                              <label class="form-check-label mb-0" for="ordercheck">&nbsp;</label>
                            </div>
                          </th>
                          <th
                            class="sorting sorting_asc"
                            tabindex="0"
                            aria-controls="DataTables_Table_0"
                            rowspan="1"
                            colspan="1"
                            style="width: 150px"
                            aria-sort="ascending"
                            aria-label="Order ID: activate to sort column descending">
                            Club
                          </th>
                          <th
                            class="sorting"
                            tabindex="0"
                            aria-controls="DataTables_Table_0"
                            rowspan="1"
                            colspan="1"
                            style="width: 150px"
                            aria-label="Date: activate to sort column ascending">
                            Date
                          </th>
                          <th
                            class="sorting"
                            tabindex="0"
                            aria-controls="DataTables_Table_0"
                            rowspan="1"
                            colspan="1"
                            style="width: 150px"
                            aria-label="Billing Name: activate to sort column ascending">
                            Signups #
                          </th>
                          <th
                            class="sorting"
                            tabindex="0"
                            aria-controls="DataTables_Table_0"
                            rowspan="1"
                            colspan="1"
                            style="width: 150px"
                            aria-label="Total: activate to sort column ascending">
                            Returning Customers
                          </th>
                          <th
                            class="sorting"
                            tabindex="0"
                            aria-controls="DataTables_Table_0"
                            rowspan="1"
                            colspan="1"
                            style="width: 117px"
                            aria-label="Payment Status: activate to sort column ascending">
                            Singup List
                          </th>
                          <!-- <th style="width: 80px" class="sorting_disabled" rowspan="1" colspan="1" aria-label="Action">
                            Action
                          </th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="odd">
                          <td class="dtr-control">
                            <div class="form-check">
                              <input type="checkbox" class="form-check-input" id="ordercheck10" />
                              <label class="form-check-label mb-0" for="ordercheck10">&nbsp;</label>
                            </div>
                          </td>

                          <td class="sorting_1">
                            <a href="javascript: void(0);" class="text-reset fw-bold">Livingstone Athletics</a>
                          </td>
                          <td>October 16</td>
                          <td>2</td>

                          <td>1</td>
                          <td>
                            <div class="badge bg-warning-subtle text-warning font-size-12">unpaid</div>
                          </td>
                          <td id="tooltip-container9">
                            <a
                              href="javascript:void(0);"
                              class="me-3 text-primary"
                              data-bs-container="#tooltip-container9"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              aria-label="Edit"
                              data-bs-original-title="Edit"
                              ><i class="mdi mdi-pencil font-size-18"></i
                            ></a>
                            <a
                              href="javascript:void(0);"
                              class="text-danger"
                              data-bs-container="#tooltip-container9"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              aria-label="Delete"
                              data-bs-original-title="Delete"
                              ><i class="mdi mdi-trash-can font-size-18"></i
                            ></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- <div class="row">
                <div class="col-sm-12 col-md-5">
                  <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                    Showing 1 to 5 of 10 entries
                  </div>
                </div>

              </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-5 d-none">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Top Gyms (Revenue Per Event)</h4>

          <div class="table-responsive">
            <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
              <div class="row">
                <div class="row">
                  <div class="col-sm-12">
                    <table
                      class="table table-centered datatable dt-responsive nowrap dataTable no-footer dtr-inline"
                      data-bs-page-length="5"
                      style="border-collapse: collapse; border-spacing: 0px; width: 100%"
                      id="DataTables_Table_0"
                      aria-describedby="DataTables_Table_0_info">
                      <thead class="table-light">
                        <tr>
                          <th
                            style="width: 29px"
                            class="sorting_disabled"
                            rowspan="1"
                            colspan="1"
                            aria-label="


                                      &amp;nbsp;

                              ">
                            <div class="form-check">
                              <input type="checkbox" class="form-check-input" id="ordercheck" />
                              <label class="form-check-label mb-0" for="ordercheck">&nbsp;</label>
                            </div>
                          </th>
                          <th
                            class="sorting sorting_asc"
                            tabindex="0"
                            aria-controls="DataTables_Table_0"
                            rowspan="1"
                            colspan="1"
                            style="width: 150px"
                            aria-sort="ascending"
                            aria-label="Order ID: activate to sort column descending">
                            Club
                          </th>
                          <th
                            class="sorting"
                            tabindex="0"
                            aria-controls="DataTables_Table_0"
                            rowspan="1"
                            colspan="1"
                            style="width: 150px"
                            aria-label="Date: activate to sort column ascending">
                            Date
                          </th>
                          <th
                            class="sorting"
                            tabindex="0"
                            aria-controls="DataTables_Table_0"
                            rowspan="1"
                            colspan="1"
                            style="width: 150px"
                            aria-label="Billing Name: activate to sort column ascending">
                            Signups #
                          </th>
                          <th
                            class="sorting"
                            tabindex="0"
                            aria-controls="DataTables_Table_0"
                            rowspan="1"
                            colspan="1"
                            style="width: 150px"
                            aria-label="Total: activate to sort column ascending">
                            Returning Customers
                          </th>
                          <th
                            class="sorting"
                            tabindex="0"
                            aria-controls="DataTables_Table_0"
                            rowspan="1"
                            colspan="1"
                            style="width: 117px"
                            aria-label="Payment Status: activate to sort column ascending">
                            Singup List
                          </th>
                          <!-- <th style="width: 80px" class="sorting_disabled" rowspan="1" colspan="1" aria-label="Action">
                            Action
                          </th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="odd">
                          <td class="dtr-control">
                            <div class="form-check">
                              <input type="checkbox" class="form-check-input" id="ordercheck10" />
                              <label class="form-check-label mb-0" for="ordercheck10">&nbsp;</label>
                            </div>
                          </td>

                          <td class="sorting_1">
                            <a href="javascript: void(0);" class="text-reset fw-bold">Livingstone Athletics</a>
                          </td>
                          <td>October 16</td>
                          <td>2</td>

                          <td>1</td>
                          <td>
                            <div class="badge bg-warning-subtle text-warning font-size-12">unpaid</div>
                          </td>
                          <td id="tooltip-container9">
                            <a
                              href="javascript:void(0);"
                              class="me-3 text-primary"
                              data-bs-container="#tooltip-container9"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              aria-label="Edit"
                              data-bs-original-title="Edit"
                              ><i class="mdi mdi-pencil font-size-18"></i
                            ></a>
                            <a
                              href="javascript:void(0);"
                              class="text-danger"
                              data-bs-container="#tooltip-container9"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              aria-label="Delete"
                              data-bs-original-title="Delete"
                              ><i class="mdi mdi-trash-can font-size-18"></i
                            ></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- <div class="row">
                <div class="col-sm-12 col-md-5">
                  <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                    Showing 1 to 5 of 10 entries
                  </div>
                </div>

              </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-5 d-none">
    <div class="col-lg-8">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Latest Transactions</h4>

          <div class="table-responsive">
            <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
              <div class="row">
                <!-- <div class="col-sm-12 col-md-6">
                  <div class="dataTables_length" id="DataTables_Table_0_length">
                    <label
                      >Show
                      <select
                        name="DataTables_Table_0_length"
                        aria-controls="DataTables_Table_0"
                        class="custom-select custom-select-sm form-control form-control-sm form-select form-select-sm">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                      </select>
                      entries</label
                    >
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div id="DataTables_Table_0_filter" class="dataTables_filter">
                    <label
                      >Search:<input
                        type="search"
                        class="form-control form-control-sm"
                        placeholder=""
                        aria-controls="DataTables_Table_0"
                    /></label>
                  </div>
                </div>
              </div> -->
                <div class="row">
                  <div class="col-sm-12">
                    <table
                      class="table table-centered datatable dt-responsive nowrap dataTable no-footer dtr-inline"
                      data-bs-page-length="5"
                      style="border-collapse: collapse; border-spacing: 0px; width: 100%"
                      id="DataTables_Table_0"
                      aria-describedby="DataTables_Table_0_info">
                      <thead class="table-light">
                        <tr>
                          <th
                            style="width: 29px"
                            class="sorting_disabled"
                            rowspan="1"
                            colspan="1"
                            aria-label="


                                      &amp;nbsp;

                              ">
                            <div class="form-check">
                              <input type="checkbox" class="form-check-input" id="ordercheck" />
                              <label class="form-check-label mb-0" for="ordercheck">&nbsp;</label>
                            </div>
                          </th>
                          <th
                            class="sorting sorting_asc"
                            tabindex="0"
                            aria-controls="DataTables_Table_0"
                            rowspan="1"
                            colspan="1"
                            style="width: 64px"
                            aria-sort="ascending"
                            aria-label="Order ID: activate to sort column descending">
                            Order ID
                          </th>
                          <th
                            class="sorting"
                            tabindex="0"
                            aria-controls="DataTables_Table_0"
                            rowspan="1"
                            colspan="1"
                            style="width: 83px"
                            aria-label="Date: activate to sort column ascending">
                            Date
                          </th>
                          <th
                            class="sorting"
                            tabindex="0"
                            aria-controls="DataTables_Table_0"
                            rowspan="1"
                            colspan="1"
                            style="width: 96px"
                            aria-label="Billing Name: activate to sort column ascending">
                            Billing Name
                          </th>
                          <th
                            class="sorting"
                            tabindex="0"
                            aria-controls="DataTables_Table_0"
                            rowspan="1"
                            colspan="1"
                            style="width: 38px"
                            aria-label="Total: activate to sort column ascending">
                            Total
                          </th>
                          <th
                            class="sorting"
                            tabindex="0"
                            aria-controls="DataTables_Table_0"
                            rowspan="1"
                            colspan="1"
                            style="width: 117px"
                            aria-label="Payment Status: activate to sort column ascending">
                            Payment Status
                          </th>
                          <th style="width: 80px" class="sorting_disabled" rowspan="1" colspan="1" aria-label="Action">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="odd">
                          <td class="dtr-control">
                            <div class="form-check">
                              <input type="checkbox" class="form-check-input" id="ordercheck10" />
                              <label class="form-check-label mb-0" for="ordercheck10">&nbsp;</label>
                            </div>
                          </td>

                          <td class="sorting_1">
                            <a href="javascript: void(0);" class="text-reset fw-bold">#NZ1563</a>
                          </td>
                          <td>28 Mar, 2020</td>
                          <td>Frank Dean</td>

                          <td>$164</td>
                          <td>
                            <div class="badge bg-warning-subtle text-warning font-size-12">unpaid</div>
                          </td>
                          <td id="tooltip-container9">
                            <a
                              href="javascript:void(0);"
                              class="me-3 text-primary"
                              data-bs-container="#tooltip-container9"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              aria-label="Edit"
                              data-bs-original-title="Edit"
                              ><i class="mdi mdi-pencil font-size-18"></i
                            ></a>
                            <a
                              href="javascript:void(0);"
                              class="text-danger"
                              data-bs-container="#tooltip-container9"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              aria-label="Delete"
                              data-bs-original-title="Delete"
                              ><i class="mdi mdi-trash-can font-size-18"></i
                            ></a>
                          </td>
                        </tr>
                        <tr class="even">
                          <td class="dtr-control">
                            <div class="form-check">
                              <input type="checkbox" class="form-check-input" id="ordercheck9" />
                              <label class="form-check-label mb-0" for="ordercheck9">&nbsp;</label>
                            </div>
                          </td>

                          <td class="sorting_1">
                            <a href="javascript: void(0);" class="text-reset fw-bold">#NZ1564</a>
                          </td>
                          <td>28 Mar, 2020</td>
                          <td>Eddy Torres</td>

                          <td>$141</td>
                          <td>
                            <div class="badge bg-success-subtle text-success font-size-12">Paid</div>
                          </td>
                          <td id="tooltip-container8">
                            <a
                              href="javascript:void(0);"
                              class="me-3 text-primary"
                              data-bs-container="#tooltip-container8"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              aria-label="Edit"
                              data-bs-original-title="Edit"
                              ><i class="mdi mdi-pencil font-size-18"></i
                            ></a>
                            <a
                              href="javascript:void(0);"
                              class="text-danger"
                              data-bs-container="#tooltip-container8"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              aria-label="Delete"
                              data-bs-original-title="Delete"
                              ><i class="mdi mdi-trash-can font-size-18"></i
                            ></a>
                          </td>
                        </tr>
                        <tr class="odd">
                          <td class="dtr-control">
                            <div class="form-check">
                              <input type="checkbox" class="form-check-input" id="ordercheck8" />
                              <label class="form-check-label mb-0" for="ordercheck8">&nbsp;</label>
                            </div>
                          </td>

                          <td class="sorting_1">
                            <a href="javascript: void(0);" class="text-reset fw-bold">#NZ1565</a>
                          </td>
                          <td>29 Mar, 2020</td>
                          <td>Jamison Clark</td>

                          <td>$123</td>
                          <td>
                            <div class="badge bg-success-subtle text-success font-size-12">Paid</div>
                          </td>
                          <td id="tooltip-container7">
                            <a
                              href="javascript:void(0);"
                              class="me-3 text-primary"
                              data-bs-container="#tooltip-container7"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              aria-label="Edit"
                              data-bs-original-title="Edit"
                              ><i class="mdi mdi-pencil font-size-18"></i
                            ></a>
                            <a
                              href="javascript:void(0);"
                              class="text-danger"
                              data-bs-container="#tooltip-container7"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              aria-label="Delete"
                              data-bs-original-title="Delete"
                              ><i class="mdi mdi-trash-can font-size-18"></i
                            ></a>
                          </td>
                        </tr>
                        <tr class="even">
                          <td class="dtr-control">
                            <div class="form-check">
                              <input type="checkbox" class="form-check-input" id="ordercheck7" />
                              <label class="form-check-label mb-0" for="ordercheck7">&nbsp;</label>
                            </div>
                          </td>

                          <td class="sorting_1">
                            <a href="javascript: void(0);" class="text-reset fw-bold">#NZ1566</a>
                          </td>
                          <td>30 Mar, 2020</td>
                          <td>Jewel Buckley</td>

                          <td>$112</td>
                          <td>
                            <div class="badge bg-success-subtle text-success font-size-12">Paid</div>
                          </td>
                          <td id="tooltip-container6">
                            <a
                              href="javascript:void(0);"
                              class="me-3 text-primary"
                              data-bs-container="#tooltip-container6"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              aria-label="Edit"
                              data-bs-original-title="Edit"
                              ><i class="mdi mdi-pencil font-size-18"></i
                            ></a>
                            <a
                              href="javascript:void(0);"
                              class="text-danger"
                              data-bs-container="#tooltip-container6"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              aria-label="Delete"
                              data-bs-original-title="Delete"
                              ><i class="mdi mdi-trash-can font-size-18"></i
                            ></a>
                          </td>
                        </tr>
                        <tr class="odd">
                          <td class="dtr-control">
                            <div class="form-check">
                              <input type="checkbox" class="form-check-input" id="ordercheck6" />
                              <label class="form-check-label mb-0" for="ordercheck6">&nbsp;</label>
                            </div>
                          </td>

                          <td class="sorting_1">
                            <a href="javascript: void(0);" class="text-reset fw-bold">#NZ1567</a>
                          </td>
                          <td>31 Mar, 2020</td>
                          <td>Jeffrey Waltz</td>

                          <td>$105</td>
                          <td>
                            <div class="badge bg-warning-subtle text-warning font-size-12">unpaid</div>
                          </td>
                          <td id="tooltip-container5">
                            <a
                              href="javascript:void(0);"
                              class="me-3 text-primary"
                              data-bs-container="#tooltip-container5"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              aria-label="Edit"
                              data-bs-original-title="Edit"
                              ><i class="mdi mdi-pencil font-size-18"></i
                            ></a>
                            <a
                              href="javascript:void(0);"
                              class="text-danger"
                              data-bs-container="#tooltip-container5"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              aria-label="Delete"
                              data-bs-original-title="Delete"
                              ><i class="mdi mdi-trash-can font-size-18"></i
                            ></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- <div class="row">
                <div class="col-sm-12 col-md-5">
                  <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                    Showing 1 to 5 of 10 entries
                  </div>
                </div>

              </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
