import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { catchError, Observable, of, tap, throwError } from 'rxjs';
import { ApiLocalRoutesEnum, SharedErrorService, SharedLogService } from '@bodyanalytics/ui-core';
import { CustomerI } from '@bodyanalytics/data-models-ui';
import { ENV } from '@bodyanalytics/app-config';

@Injectable({
  providedIn: 'root',
})
export class ManageCustomerService {
  private customersUrl = `${ApiLocalRoutesEnum.API_SUFFIX_CUSTOMERS}`;
  private customersDetailsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_CUSTOMERS_DETAILS}`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(
    @Inject(ENV) private appConfig: any,
    private http: HttpClient,
    private sharedLogService: SharedLogService,
    private sharedErrorService: SharedErrorService,
  ) {}

  getCustomer(id: string): Observable<any> {
    const url = `${this.appConfig.api}/${this.customersDetailsUrl}/${id}`;
    return this.http.get<any>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched customer id=${id}`)),
      catchError(this.sharedErrorService.handleError<CustomerI>(`getCustomer id=${id}`)),
    );
  }

  load(): Observable<CustomerI[]> {
    const url = `${this.appConfig.api}/${this.customersUrl}`;
    return this.http.get<CustomerI[]>(url).pipe(
      tap(_ => console.log('fetched customers')),
      catchError(this.sharedErrorService.handleError<CustomerI[]>('load customers', [])),
    );
  }

  duplicateCustomer(id: string, customer: CustomerI): Observable<any> {
    const url = `${this.appConfig.api}/${this.customersDetailsUrl}/${id}`;
    return this.http.patch(url, customer, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`updated customer id=${id}`)),
      catchError(this.sharedErrorService.handleError<any>('updateCustomer')),
    );
  }
  updateCustomer(id: string, customer: CustomerI): Observable<any> {
    const url = `${this.appConfig.api}/${this.customersDetailsUrl}/${id}`;
    return this.http.patch(url, customer, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`updated customer id=${id}`)),
      catchError(this.sharedErrorService.handleError<any>('updateCustomer')),
    );
  }

  deleteCustomer(id: string): Observable<CustomerI> {
    const url = `${this.appConfig.api}/${this.customersUrl}/${id}`;

    return this.http.delete<CustomerI>(url, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`deleted customer id=${id}`)),
      catchError(this.sharedErrorService.handleError<CustomerI>('deleteCustomer')),
    );
  }

  getCustomers(): Observable<CustomerI[]> {
    const url = `${this.appConfig.api}/${this.customersUrl}`;
    return this.http.get<CustomerI[]>(url).pipe(
      tap(_ => console.log('fetched customers')),
      catchError(this.sharedErrorService.handleError<CustomerI[]>('getCustomers', [])),
    );
  }

  addCustomer(customer: CustomerI): Observable<CustomerI> {
    const url = `${this.appConfig.api}/${this.customersUrl}`;

    return this.http.post<CustomerI>(url, customer, this.httpOptions).pipe(
      tap((newCustomer: CustomerI) => this.sharedLogService.log(`added customer w/ id=${newCustomer.id}`)),
      catchError(this.sharedErrorService.handleError<CustomerI>('addCustomer')),
    );
  }

  // sendSms(customer: CustomerI): Observable<CustomerI> {
  //   return this.http.post<CustomerI>(this.customersUrl, customer, this.httpOptions).pipe(
  //     tap((newCustomer: CustomerI) => this.sharedLogService.log(`added customer w/ id=${newCustomer.id}`)),
  //     catchError(this.sharedErrorService.handleError<CustomerI>('addCustomer')),
  //   );
  // }

  /* GET customers whose name contains search term */
  searchCustomers(term: string): Observable<CustomerI[]> {
    const url = `${this.customersUrl}/search/?firstName=${term}`;
    console.log('here: ', term);
    if (!term.trim()) {
      // if not search term, return empty customer array.
      return of([]);
    }

    return this.http.get<CustomerI[]>(`${url}`).pipe(
      tap(x =>
        x.length ? console.log(`found customers matching "${term}"`) : console.log(`no customers matching "${term}"`),
      ),
      catchError(this.sharedErrorService.handleError<CustomerI[]>('searchCustomers', [])),
    );
  }
}
