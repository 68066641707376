import { UserI } from './user';

export interface BodyMeasurementI {
  id: string;
  userid: string;
  // bodymeasurements_details: BodyFatTestI
  emailAddress?: string;
  user: UserI;
  // id?: string;
  // testDate?: string;
  // phoneNumber?: string;
  // emailAddress?: string;
  // age?: number;
  // gender?: string;
  // // weight?: number;//POSSIBLE DUPLICATE
  // dryWeight?: number; //POSSIBLE DUPLICATE
  // height?: number;
  // salinity?: string;
  // waterTemperature?: number; //
  // wetWeightKg?: number;
  // residualLungValue?: number;
  // fatLbs?: number;
  // fatPercent?: number; //POSSIBLE DUPLICATE
  // // bodyFatPercent?: number;//POSSIBLE DUPLICATE
  // leanLbs?: number;
  // leanMassPercent?: number;
  // waterLbs?: number; //POSSIBLE DUPLICATE
  // // wetWeight?: number;//POSSIBLE DUPLICATE
  // restingMetabolicRate?: number;
  // isTraining?: boolean;
  // bodyFatTestForCustomerId?: string | null;
  // bodyFatTestSavedInProfileId?: string | null;
  // bodyFatTestEventClubId?: string | null;
  // bodyFatTestEventOperatorId?: string | null;
  // bodyFatTestEventAssignedTruckId?: string | null;
  // bodyFatTestNote?: string | null;
  // bodyFatTestNoteId?: string | null;
  // bodyFatTestCompletedSurveyTDEEId?: string | null;
  // bodyFatTestCompletedSurveyMACROSId?: string | null;
  // bodyFatMeasureTestOrderId?: string | null;
  // bodyFatTestPaymentDetailId?: string | null;
  // currentStep?: string | null;
  // isExistingUser?: boolean; // if users have uid
  // isTestCompleted?: boolean;
  // createdAt?: string;
  // updatedAt?: string;
}

export interface BodyFatTestI {
  assignedTruck?: string;
  assignedEvent?: string;
  assignedOperator?: string;
  assignedEventClub?: string;
  assignedClub?: string;
  assignedCheckinSessionId?: string;
  bodyFatTestForCustomer?: ClientDetails;
  customerId?: string;
  testDetails?: TestData;
  // id: string;
  userid?: string;
  // bodymeasurements_details: BodyFatTestI
  emailAddress?: string;
  user?: UserI;

  id?: string;
  bodyFatMeasureTestReportId?: string;
  ethnicity?: string;
  birthDate?: string;

  age?: number;

  createdAt?: string;
  dryWeight?: string | number;
  v02MaX?: string | number;
  fatLbs?: number;
  fatPercent?: string | number;
  sex?: string;
  gender?: string;
  height?: number;
  isTraining?: boolean;
  leanLbs?: number;
  leanMassPercent?: number;
  waterMassPercent?: number;
  note?: null;
  residualLungValue?: number;
  restingMetabolicRate?: number;
  salinity?: string;
  testDate?: string;
  testTime?: string;
  uid?: null;
  updatedAt?: string;
  // user_bodymeasurements_details_id?: string;
  waterLbs?: number;
  waterTemperature?: number;
  wetKg?: number;

  // testDate?: string;
  phoneNumber?: string;
  // emailAddress?: string;
  // age?: number;
  // gender?: string;
  // weight?: number;//POSSIBLE DUPLICATE
  // dryWeight?: number; //POSSIBLE DUPLICATE
  // height?: number;
  // salinity?: string;
  // waterTemperature?: number; //
  wetWeightKg?: number;
  // residualLungValue?: number;
  // fatLbs?: number;
  // fatPercent?: number; //POSSIBLE DUPLICATE
  // bodyFatPercent?: number;//POSSIBLE DUPLICATE
  // leanLbs?: number;
  // leanMassPercent?: number;
  // waterLbs?: number; //POSSIBLE DUPLICATE
  // wetWeight?: number;//POSSIBLE DUPLICATE
  // restingMetabolicRate?: number;
  // isTraining?: boolean;
  bodyFatTestForCustomerId?: string | null;
  bodyFatTestSavedInProfileId?: string | null;
  bodyFatTestEventClubId?: string | null;
  bodyFatTestEventOperatorId?: string | null;
  bodyFatTestEventAssignedTruckId?: string | null;
  bodyFatTestNote?: string | null;
  bodyFatTestNoteId?: string | null;
  bodyFatTestCompletedSurveyTDEEId?: string | null;
  bodyFatTestCompletedSurveyMACROSId?: string | null;
  bodyFatMeasureTestOrderId?: string | null;
  bodyFatTestPaymentDetailId?: string | null;
  currentStep?: string | null;
  isExistingUser?: boolean; // if users have uid
  isTestCompleted?: boolean;
  // createdAt?: string;
  // updatedAt?: string;
  // id: string;
  // userid: string;

  // id?: string;
  // testDate?: string;
  // phoneNumber?: string;
  // emailAddress?: string;
  // age?: number;
  // gender?: string;
  // // weight?: number;//POSSIBLE DUPLICATE
  // dryWeight?: number; //POSSIBLE DUPLICATE
  // height?: number;
  // salinity?: string;
  // waterTemperature?: number; //
  // wetWeightKg?: number;
  // residualLungValue?: number;
  // fatLbs?: number;
  // fatPercent?: number; //POSSIBLE DUPLICATE
  // // bodyFatPercent?: number;//POSSIBLE DUPLICATE
  // leanLbs?: number;
  // leanMassPercent?: number;
  // waterLbs?: number; //POSSIBLE DUPLICATE
  // // wetWeight?: number;//POSSIBLE DUPLICATE
  // restingMetabolicRate?: number;
  // isTraining?: boolean;
  // bodyFatTestForCustomerId?: string | null;
  // bodyFatTestSavedInProfileId?: string | null;
  // bodyFatTestEventClubId?: string | null;
  // bodyFatTestEventOperatorId?: string | null;
  // bodyFatTestEventAssignedTruckId?: string | null;
  // bodyFatTestNote?: string | null;
  // bodyFatTestNoteId?: string | null;
  // bodyFatTestCompletedSurveyTDEEId?: string | null;
  // bodyFatTestCompletedSurveyMACROSId?: string | null;
  // bodyFatMeasureTestOrderId?: string | null;
  // bodyFatTestPaymentDetailId?: string | null;
  // currentStep?: string | null;
  // isExistingUser?: boolean; // if users have uid
  // isTestCompleted?: boolean;
  // createdAt?: string;
  // updatedAt?: string;
}

export interface BodyFatTestReportI {
  id: string;
  customer: BodyFatTestForCustomerI;
  report: BodyFatReportI;
  bodyFatMeasureTestReportId:string;

}
export interface BodyFatReportI {
  id: string;
  testDate?: string;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
  bodyFatTestSessionTestId?: string;
  testDetails: BodyFatTestDetails;
}

export interface BodyFatTestDetails {
  id: string;
  dryWeight?: number;
  height?: number;
  wetKg?: number;
  age?: number;
  sex?: string;
  gender?: string;
  salinity?: number;
  waterTemperature?: number;
  waterMassPercent?: number;
  residualLungValue?: number;
  fatLbs?: number;
  fatPercent?: number;
  leanLbs?: number;
  leanMassPercent?: number;
  waterLbs?: number;
  restingMetabolicRate?: number;
  createdAt?: string;
  updatedAt?: string;
  bodyFatMeasureTestSessionCheckInId?: string;
  bodyFatMeasureTestSessionCheckIn: BodyFatMeasureTestSessionCheckInI;
}
export interface BodyFatMeasureTestSessionCheckInI {
  bodyFatTestForCustomer: BodyFatTestForCustomerI;
  bodyFatMeasureTestSessionSetup: BodyFatMeasureTestSessionSetupI;
}

export interface BodyFatMeasureTestSessionSetupI {
  bodyFatTestEvent: BodyFatTestEventI;
  bodyFatTestEventOperator: BodyFatTestEventOperatorI;
  bodyFatTestEventAssignedTruck: BodyFatTestEventAssignedTruckI;
}
export interface BodyFatTestEventAssignedTruckI {
  truckName: string;
}
export interface BodyFatTestEventOperatorI {
  operatorUserName: string;
}
export interface BodyFatTestEventI {
  testEventName: string;
}
export interface BodyFatTestForCustomerI {
  firstName: string;
  phoneNumber: string;
  emailAddress: string;
}
export interface BodyMeasurementReportI {
  id?: string;
  sessionDetails: {
    testDate?: string;
    testTime?: string;
    amountPaid?: number;
    operatorName: string;
    clubName: string;
    truckName: string;
    bodyFatClubId?: string;
    bodyFatTestEventAssignedTruckId?: string;
    bodyFatTestEventClubId?: string;
    bodyFatTestEventOperatorId?: string;
    waterTemperature?: number;
    testData?: TestData;
  };

  clientDetails: ClientDetails;

  // phoneNumber?: string;
  // emailAddress?: string;
  // age?: number;
  // gender?: string;
  // // weight?: number;//POSSIBLE DUPLICATE
  // dryWeight?: number; //POSSIBLE DUPLICATE
  // height?: number;
  // salinity?: string;
  // waterTemperature?: number; //
  // wetWeightKg?: number;
  // residualLungValue?: number;
  // fatLbs?: number;
  // fatPercent?: number; //POSSIBLE DUPLICATE
  // // bodyFatPercent?: number;//POSSIBLE DUPLICATE
  // leanLbs?: number;
  // leanMassPercent?: number;
  // waterLbs?: number; //POSSIBLE DUPLICATE
  // // wetWeight?: number;//POSSIBLE DUPLICATE
  // restingMetabolicRate?: number;
  // isTraining?: boolean;

  clientHistory: ClientHistory[];

  // bodyFatTestForCustomerId?: string | null;
  // bodyFatTestSavedInProfileId?: string | null;
  // bodyFatTestEventClubId?: string | null;
  // bodyFatTestEventOperatorId?: string | null;
  // bodyFatTestEventAssignedTruckId?: string | null;
  // bodyFatTestNote?: string | null;
  // bodyFatTestNoteId?: string | null;
  // bodyFatTestCompletedSurveyTDEEId?: string | null;
  // bodyFatTestCompletedSurveyMACROSId?: string | null;
  // bodyFatMeasureTestOrderId?: string | null;
  // bodyFatTestPaymentDetailId?: string | null;
  // currentStep?: string | null;
  // isExistingUser?: boolean; // if users have uid
  // isTestCompleted?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface BodyFatTestRequest {
  dryWeight: number;
  height: number;
  wetWeight: number;
  waterTemperature: number;
  currentStep: string;
  isTestCompleted: boolean;
}
export interface BodyFatTestResponse {
  dryWeight: number;
  height: number;
  wetWeight: number;
  waterTemperature: number;
  fatLbs: number;
  fatPercent: number;
  leanLbs: number;
  leanMassPercent: number;
  waterLbs: number;
  restingMetabolicRate: number;
}

export interface ClientDetails {
  bodyFatTestForCustomerId?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  height?: number;
  age?: number;
  gender?: string;
}

export interface TestData {
  id?: string;
  testDate?: string;
  height?: number;
  dryWeight?: number; //POSSIBLE DUPLICATE
  wetWeightKg?: number;
  fatLbs?: number;
  residualLungValue?: number;
  fatPercent?: number;
  restingMetabolicRate?: number;
  waterLbs?: number;
  leanMassPercent?: number;
  leanLbs?: number;
  wetKg?: number;
  fatPercentChange?: number;
  weightChange?: number;
  leanLbChange?: number;
  fatLbChange?: number;
  fatPercentDiff?: number;
}

export interface ClientHistory {
  id?: string;
  date: string;
  // age?: number;
  // salinity?: number;
  // sex?: string;
  // waterMassPercent?: number;
  // waterTemperature?: string;
  // gender?: string;
  // createdAt?: string
  // updatedAt?: string
  testDate?: string;
  height?: number;
  dryWeight?: number; //POSSIBLE DUPLICATE
  wetWeightKg?: number;
  fatLbs?: number;
  residualLungValue?: number;
  fatPercent?: number;
  restingMetabolicRate?: number;
  waterLbs?: number;
  leanMassPercent?: number;
  leanLbs?: number;
  wetKg?: number;
  fatPercentChange?: number;
  weightChange?: number;
  leanLbChange?: number;
  fatLbChange?: number;
  fatPercentDiff?: number;
  weightPercentDiff: string;
  leanLbsDiff: string;
  fatLbsDiff: string;
  bodyFatPercentDiff: string;
}

export interface TestSessionSetupI {
  id?: string;
  bodyFatTestEventClubId?: string;
  bodyFatTestEventOperatorId?: string;
  bodyFatTestEventAssignedTruckId?: string;
  waterTemperature?: number;
  status?: string;
  workFlowStep?: string;
}

export interface TestSessionCheckInI {
  id?: string;
  // bodyFatTestEventClubId?: string;
  bodyFatTestForCustomerId?: string;
  bodyFatMeasureTestSessionSetupId?: string;
  // waterTemperature?: number;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
  // id: 23085bcb-a7e3-4971-b399-716374691349,
  // firstName: Ricky,
  // lastName: Auer,
  // sex: FEMALE,
  // gender: Transexual man,
  // birthDate: 1968-05-12T06:20:47.353Z,
  // phoneNumber: 1-878-920-6090 x00657,
  // address1: 40775 Silas Ports,
  // address2: null,
  // city: Huelstown,
  // state: NE,
  // zipCode: 44375,
  // uid: null,
  // emailAddress: Clemmie_King90@hotmail.com,
  // age: 0,
  // isEmployee: false,
  // useSiri3: false,
  // isSiri3: false,
  // ethnicity: White,
  // race: null,
  // isAfricanAmerican: false,
  // customerTeamsId: null,
  // customerPreferredClubId: null,
  // createdAt: 2023-10-28T22:33:15.356Z,
  // updatedAt: 2023-10-28T22:33:15.356Z,
  // updateDate: null
}
export interface TestSessionTestStepI {
  id?: string;
  // bodyFatTestEventClubId?: string;
  testDate?: string;
  height?: number;
  dryWeight?: number; //POSSIBLE DUPLICATE
  wetWeightKg?: number;
  testSessionCheckInId?: string;
  // bodyFatMeasureTestSessionSetupId?: string;
  // waterTemperature?: number;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
  // id: 23085bcb-a7e3-4971-b399-716374691349,
  // firstName: Ricky,
  // lastName: Auer,
  // sex: FEMALE,
  // gender: Transexual man,
  // birthDate: 1968-05-12T06:20:47.353Z,
  // phoneNumber: 1-878-920-6090 x00657,
  // address1: 40775 Silas Ports,
  // address2: null,
  // city: Huelstown,
  // state: NE,
  // zipCode: 44375,
  // uid: null,
  // emailAddress: Clemmie_King90@hotmail.com,
  // age: 0,
  // isEmployee: false,
  // useSiri3: false,
  // isSiri3: false,
  // ethnicity: White,
  // race: null,
  // isAfricanAmerican: false,
  // customerTeamsId: null,
  // customerPreferredClubId: null,
  // createdAt: 2023-10-28T22:33:15.356Z,
  // updatedAt: 2023-10-28T22:33:15.356Z,
  // updateDate: null
}
