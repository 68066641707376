<form [formGroup]="testingEventDetailsForm" (ngSubmit)="formSubmit()">
  <div class="row">
    <div class="col-md-7">
      <!-- Conditionally display Event Name input -->
      <div class="form-group row mt-2" *ngIf="testingEventDetailsForm.get('isDisplayEventName')?.value">
        <label for="testEventName" class="col-sm-4 col-form-label">Event Name:</label>
        <div class="col-sm-8">
          <input
            type="text"
            id="testEventName"
            class="form-control form-control-lg"
            [ngClass]="{ 'is-invalid': formsubmit && form.testEventName.errors }"
            formControlName="testEventName"
            autocomplete="off" />
          <div *ngIf="formsubmit && form.testEventName.errors" class="invalid-tooltip">
            <span *ngIf="form.testEventName.errors['required']">Name is required.</span>
          </div>
        </div>
      </div>

      <!-- Conditionally display Event Description input -->
      <div class="form-group row mt-2" *ngIf="testingEventDetailsForm.get('isDisplayEventDescription')?.value">
        <label for="testEventDescription" class="col-sm-4 col-form-label">Event Description:</label>
        <div class="col-sm-8">
          <input
            type="text"
            id="testEventDescription"
            class="form-control form-control-lg"
            [ngClass]="{ 'is-invalid': formsubmit && form.testEventDescription.errors }"
            formControlName="testEventDescription"
            autocomplete="off" />
          <div *ngIf="formsubmit && form.testEventDescription.errors" class="invalid-tooltip">
            <span *ngIf="form.testEventDescription.errors['required']">Description is required.</span>
          </div>
        </div>
      </div>

      <!-- Other form fields -->
      <div class="form-group row mt-2">
        <label for="drpEventStatus" class="col-sm-4 col-form-label">Event Status:</label>
        <div class="col-sm-8">
          <select
            id="drpEventStatus"
            class="form-select form-select-lg mb-3"
            name="drpEventStatus"
            formControlName="status">
            <option value="null">Please Select</option>
            <option *ngFor="let event of eventStatusJson" [value]="event.statusValue">
              {{ event.statusValue }}
            </option>
          </select>
        </div>
        <div *ngIf="formsubmit && form.status.errors" class="invalid-tooltip">
          <span *ngIf="form.status.errors['required']">Event status is required.</span>
        </div>
      </div>

      <div class="form-group row mt-2">
        <label for="testEventDateInput" class="col-sm-4 col-form-label">Event Date:</label>
        <div class="col-sm-8 position-relative mb-4">
          <input
            type="date"
            name="testEventDateInput"
            id="testEventDateInput"
            [ngClass]="{ 'is-invalid': formsubmit && form.testEventDate.errors }"
            formControlName="testEventDate"
            class="form-control form-control-lg" />
          <div *ngIf="formsubmit && form.testEventDate.errors" class="invalid-tooltip">
            <span *ngIf="form.testEventDate.errors['required']">Date is required.</span>
          </div>
        </div>
      </div>

      <div class="form-group row mt-2">
        <label for="drpClubInput" class="col-sm-4 col-form-label">Club:</label>
        <div class="col-sm-8">
          <select
            id="drpClubInput"
            class="form-control form-select form-select-lg"
            name="clubName"
            formControlName="testEventAtClubId"
            [ngClass]="{ 'is-invalid': formsubmit && form.testEventAtClubId.errors }">
            <option value="null">Please Select</option>
            <option *ngFor="let event of eventLocation" [value]="event.id">
              {{ event?.clubName }}
            </option>
          </select>
          <div *ngIf="formsubmit && form.testEventAtClubId.errors" class="invalid-tooltip mb-3">
            <span *ngIf="form.testEventAtClubId.errors['required']">Club is required.</span>
          </div>
        </div>
      </div>

      <div class="form-group row mt-2">
        <!-- - start typing gym name -->
        <label for="drpClubInput" class="col-sm-4 col-form-label">Region: </label>
        <div class="col-sm-8">
          <select
            id="drpClubInput"
            class="form-control form-select form-select-lg"
            name="regionName"
            formControlName="regionId"
            [ngClass]="{ 'is-invalid': formsubmit && form.regionId.errors }">
            <option value="null">Please Select</option>
            <option *ngFor="let region of regions" [value]="region.id">
              {{ region?.regionName }}
            </option>
          </select>
          <div *ngIf="formsubmit && form.regionId.errors" class="invalid-tooltip mb-3">
            <span *ngIf="form.regionId.errors['required']">Region is required</span>
          </div>
        </div>
      </div>
      <div class="form-group row mt-2">
        <!-- operators -->
        <label for="drpCreatedByUser" class="col-sm-4 col-form-label">Created By:</label>
        <div class="col-sm-8">
          <select
            id="drpOperator"
            class="form-control form-select form-select-lg"
            name="createdByUser"
            [ngClass]="{ 'is-invalid': formsubmit && form.operators.errors }"
            formControlName="createdByUser">
            <option value="null">Please Select</option>
            <option *ngFor="let tenant of createdByUsers" [value]="tenant.id">
              {{ tenant.emailAddress}}
            </option>
          </select>
          <div *ngIf="formsubmit && form.createdByUser.hasError('required')" class="invalid-tooltip">
            <span *ngIf="form.createdByUser.hasError('required')">Assign company is required.</span>
          </div>
        </div>

      </div>
      <div class="form-group row mt-2">
        <!-- operators -->
        <label for="drpOperator" class="col-sm-4 col-form-label">Assign to:</label>
        <div class="col-sm-8">
          <select
            id="drpOperator"
            class="form-control form-select form-select-lg"
            name="operators"
            [ngClass]="{ 'is-invalid': formsubmit && form.operators.errors }"
            formControlName="operators">
            <option value="null">Please Select</option>
            <option *ngFor="let operator of operators" [value]="operator.id">
              {{ operator.operatorUserName }}
            </option>
          </select>
          <div *ngIf="formsubmit && form.operators.hasError('required')" class="invalid-tooltip">
            <span *ngIf="form.operators.hasError('required')">Assign operator is required.</span>
          </div>
        </div>

        <!-- <div *ngIf="formsubmit && form.operators.errors" class="invalid-tooltip">
      <span *ngIf="form.operators.errors['required']">Assign operator is required.</span>
    </div> -->
      </div>

      <div class="form-group row mt-2">
        <label for="drpTruck" class="col-sm-4 col-form-label">Assign truck: </label>
        <div class="col-sm-8">
          <select
            id="drpTruck"
            class="form-control form-select form-select-lg"
            name="trucks"
            [ngClass]="{ 'is-invalid': formsubmit && form.trucks.errors }"
            formControlName="trucks">
            <option value="null">Please Select</option>
            <option *ngFor="let truck of trucks" [value]="truck.id">
              {{ truck.truckName }}
            </option>
          </select>
        </div>
        <div *ngIf="formsubmit && form.trucks.errors" class="invalid-tooltip">
          <span *ngIf="form.trucks.errors['required']">Assign truck is required.</span>
        </div>
      </div>

      <div class="form-group row mt-2">
        <label for="drpStartTime" class="col-sm-4 col-form-label">Start Time: </label>
        <div class="col-sm-8">
          <input
            type="time"
            id="startTime"
            min="05:00"
            max="22:00"
            class="form-control form-control-lg"
            [ngClass]="{ 'is-invalid': formsubmit && form.startTime.errors }"
            formControlName="startTime"
            #startTime
            autocomplete="off" />

          <div *ngIf="formsubmit && form.startTime.errors" class="invalid-tooltip">
            <span *ngIf="form.startTime.errors['required']">Start time is required.</span>
          </div>
        </div>
      </div>

      <div class="form-group row mt-2">
        <label for="drpEndTime" class="col-sm-4 col-form-label">End Time: </label>
        <div class="col-sm-8">
          <input
            type="time"
            id="endTime"
            min="05:00"
            max="22:00"
            class="form-control form-control-lg"
            [ngClass]="{ 'is-invalid': formsubmit && form.endTime.errors }"
            formControlName="endTime"
            #endTime
            autocomplete="off" />
        </div>
        <div *ngIf="formsubmit && form.endTime.errors" class="invalid-tooltip">
          <span *ngIf="form.endTime.errors['required']">End time is required.</span>
        </div>
      </div>
      <div class="form-group row mt-2">
        <label for="drpSignupInterval" class="col-sm-4 col-form-label">Signup Time Interval:</label>
        <div class="col-sm-8">
          <select
            id="drpSignupInterval"
            [ngClass]="{ 'is-invalid': formsubmit && form.signupInterval.errors }"
            class="form-select form-select-lg mb-3"
            name="drpSignupInterval"
            formControlName="signupInterval">
            <option value="null">Please Select</option>
            <option *ngFor="let singupTime of signupIntervalJson" [value]="singupTime.intervalId">
              {{ singupTime.intervalValue }}
            </option>
          </select>
        </div>
        <div *ngIf="formsubmit && form.signupInterval.errors" class="invalid-tooltip">
          <span *ngIf="form.signupInterval.errors['required']">Signup interval is required.</span>
        </div>
      </div>

      <div class="form-group row mt-2">
        <label for="imageUrl" class="col-sm-4 col-form-label">Image URL:</label>

        <div class="col-sm-8">
          <input
            type="text"
            id="imageUrl"
            class="form-control form-control-lg"
            [ngClass]="{ 'is-invalid': formsubmit && form.imageUrl.errors }"
            formControlName="imageUrl"
            #imageUrl
            autocomplete="off" />
          <div *ngIf="formsubmit && form.imageUrl.errors" class="invalid-tooltip">
            <span *ngIf="form.imageUrl.errors['required']">Image URL is required.</span>
          </div>
        </div>
      </div>

      <div class="form-group row mt-2">
        <label for="slug" class="col-sm-4 col-form-label">Slug:</label>

        <div class="col-sm-8">
          <input
            type="text"
            id="slug"
            class="form-control form-control-lg"
            [ngClass]="{ 'is-invalid': formsubmit && form.slug.errors }"
            formControlName="slug"
            #slug
            autocomplete="off" />
          <div *ngIf="formsubmit && form.slug.errors" class="invalid-tooltip">
            <span *ngIf="form.slug.errors['required']">Slug is required.</span>
          </div>
        </div>
      </div>
      <div class="form-group row mt-2">
        <label for="drpProducts" class="col-sm-4 col-form-label">Assign Product: </label>
        <div class="col-sm-8">
          <div *ngFor="let product of products; let i = index">
            <label class="form-check-label" for="{{ product.name }}">
              <input
                class="form-check-input"
                name="{{ product.name }}"
                [value]="product.id"
                type="checkbox"
                id="{{ product.name }}"
                (change)="onCheckboxProductChange($event)" />
              {{ product.name }}
            </label>
          </div>
          <div *ngIf="formsubmit && form.productsArray.errors" class="invalid-tooltip">
            <span *ngIf="form.productsArray.errors['required']">Product is required.</span>
          </div>
        </div>
      </div>

      <div class="form-group row my-5">
        <div class="col-sm-8">
          <button class="btn btn-outline-secondary" (click)="showBreakTime()" *ngIf="!isBreakTimeSelected">
            <mat-icon aria-hidden="false" aria-label="Add icon for service" fontIcon="add"></mat-icon>

            Add Break times
          </button>
          <button class="btn btn-outline-secondary" (click)="hideBreakTime()" *ngIf="isBreakTimeSelected">
            <mat-icon aria-hidden="false" aria-label="Add icon for service" fontIcon="remove"></mat-icon>

            Hide Break times
          </button>
        </div>
      </div>
      <div *ngIf="isBreakTimeSelected">
        <div class="form-group row mt-2" *ngIf="isBreakTimeSelected">
          <label for="drpStartTime" class="col-sm-4 col-form-label">Break Start Time: </label>
          <div class="col-sm-8">
            <input
              type="time"
              id="breakStartTime"
              min="05:00"
              max="22:00"
              class="form-control form-control-lg"
              [ngClass]="{ 'is-invalid': formsubmit && form.breakStartTime.errors }"
              formControlName="breakStartTime"
              #breakStartTime
              autocomplete="off" />

            <div *ngIf="formsubmit && form.breakStartTime.errors" class="invalid-tooltip">
              <span *ngIf="form.breakStartTime.errors['required']">Start time is required.</span>
            </div>
          </div>
        </div>

        <div class="form-group row mt-2" *ngIf="isBreakTimeSelected">
          <label for="drpEndTime" class="col-sm-4 col-form-label">Break End Time: </label>
          <div class="col-sm-8">
            <input
              type="time"
              id="breakEndTime"
              min="05:00"
              max="22:00"
              class="form-control form-control-lg"
              [ngClass]="{ 'is-invalid': formsubmit && form.breakEndTime.errors }"
              formControlName="breakEndTime"
              #breakEndTime
              autocomplete="off" />
          </div>
          <div *ngIf="formsubmit && form.breakEndTime.errors" class="invalid-tooltip">
            <span *ngIf="form.breakEndTime.errors['required']">End time is required.</span>
          </div>
        </div>
      </div>

      <div class="d-flex mb-3 mt-3">
        <button type="reset" class="btn btn-secondary bg-white border-0 me-3">Cancel</button>
        <button type="submit" class="btn btn-primary" (click)="updateTestEventDetails()">Save changes</button>
      </div>
    </div>

    <div class="col-md-4 mx-auto px-4">
      <div class="form-group row mt-2">
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            id="isAllowedSignupOnlineCheckbox"
            name="isAllowedSignupOnlineCheckbox"
            formControlName="isAllowedSignupOnline" />
          <label class="form-check-label" for="isAllowedSignupOnlineCheckbox">Allow Online Signup?</label>
        </div>
      </div>
      <div class="form-group row mt-2">
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            id="isPaymentRequiredCheckbox"
            name="isPaymentRequiredCheckbox"
            formControlName="isPaymentRequired" />
          <label class="form-check-label" for="isPaymentRequiredCheckbox">Payment Required?</label>
        </div>
      </div>
      <div class="form-group row mt-2">
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            id="isTestingEventPrivateCheckbox"
            name="isTestingEventPrivateCheckbox"
            formControlName="isTestingEventPrivate" />
          <label class="form-check-label" for="isTestingEventPrivateCheckbox">Private Event?</label>
        </div>
      </div>
      <div class="form-group row">
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            id="isDisplayEventNameCheckbox"
            name="isDisplayEventNameCheckbox"
            formControlName="isDisplayEventName" />
          <label class="form-check-label" for="isDisplayEventNameCheckbox">Use custom for Event name</label>
        </div>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            id="isDisplayEventDescriptionCheckbox"
            name="isDisplayEventDescriptionCheckbox"
            formControlName="isDisplayEventDescription" />
          <label class="form-check-label" for="isDisplayEventDescriptionCheckbox">Use custom Event description</label>
        </div>
      </div>
    </div>
  </div>
</form>
