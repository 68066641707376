import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, input, OnInit, signal } from '@angular/core';
import { Validators, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { PricingPlanI } from '@bodyanalytics/data-models-ui';
import { AdminRegisterService } from '@bodyanalytics/front/admin/registration/domain';
import { MaterialModule } from '@bodyanalytics/ui-core';

@Component({
  selector: 'lib-create-tenant-form',
  standalone: true,
  imports: [CommonModule, MaterialModule, ReactiveFormsModule],
  templateUrl: './create-tenant-form.component.html',
  styleUrl: './create-tenant-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateTenantFormComponent {
  plans = input.required<PricingPlanI[]>();
   tenantForm = this.fb.group({
    name: ['', Validators.required],
    slug: [''],
    status: ['INACTIVE'],
    domain: [
      '',
      [
        Validators.required,
        Validators.pattern(/^https:\/\/www\.[a-zA-Z0-9-]+\.[a-z]{2,}$/), // Custom regex pattern
      ],
    ],
    plan: ['',Validators.required],
    settings: [{}],
    logo: [''],
    primaryColor: ['#000000'],
    secondaryColor: ['#ffffff'],
    subscriptionId: [''],
    trialEndsAt: [''],
  });

  constructor(
    private fb: FormBuilder,
    private adminRegisterService: AdminRegisterService,
  ) {
  }
  get domainControl() {
    return this.tenantForm.get('domain');
  }

  get tenantNameControl() {
    return this.tenantForm.get('name');
  }

  onSubmit() {
    if (this.tenantForm.valid) {
      this.adminRegisterService.createTenant(this.tenantForm.value).subscribe({
        next: response => console.log('Tenant created', response),
        error: error => console.error('Error creating tenant', error),
      });
    }
  }
}
