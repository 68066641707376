import { Component, OnInit, ChangeDetectionStrategy, signal, isDevMode, output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BodyanalyticsBaseComponent, TestingSessionStep, RouterUrlsEnum, GoogleActionsEnum } from '@bodyanalytics/ui-core';
import { BodyFatTestI } from '@bodyanalytics/data-models-ui';
import { FormsModule } from '@angular/forms';
import { ManageBodymeasurementService } from '@bodyanalytics/front/admin/manage-bodymeasurements/domain';
import { CustomerTestingFormComponent } from '@bodyanalytics/front/admin/manage-bodymeasurements/ui';
import { MatInputModule } from '@angular/material/input';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bodyanalytics-customer-testing',
  standalone: true,
  templateUrl: './customer-testing.component.html',
  styleUrls: ['./customer-testing.component.scss'],
  imports: [CustomerTestingFormComponent, FormsModule, MatInputModule],
})
export class CustomerTestingComponent extends BodyanalyticsBaseComponent implements OnInit {
  onSubmitEvent = output<string>();

  protected testingStep = TestingSessionStep.TEST;
  protected calculatedResults: BodyFatTestI[] = [];
  protected isResultsVisible = signal(false);
  protected isTestingCompleteFlag = false;
  protected bodyMeasurementData?: BodyFatTestI = {};
  protected checkInData: any = [];

  constructor(
    private testingService: ManageBodymeasurementService,
    private router: Router,
    private route: ActivatedRoute,
    private $gaService: GoogleAnalyticsService,
  ) {
    super();
  }

  protected complete(signupDetails: BodyFatTestI) {
    this.$gaService.event('submit_btn_test_complete', GoogleActionsEnum.USER_CLICKED_BTN);

    isDevMode() && console.log('isComplete~~~~~');
    this.testingService.stepUpdated.next(this.testingStep);

    this.bodyMeasurementData = signupDetails;

    isDevMode() && console.log('customer testing component - saveSetupTestDetails');

    // this.proceedToReview();
    this.subscriptions.barrel = this.testingService
      .completeTestData({
        isTraining: false,
        note: null,
        assignedCheckinSessionId: this.route.snapshot.paramMap.get('id') as string,
        // assignedClub: this.checkInData.bodyFatMeasureTestSessionSetup.bodyFatTestEventId,
        // assignedTruck:this.checkInData.bodyFatMeasureTestSessionSetup.bodyFatTestEventAssignedTruckId,
        // assignedEventClub: this.checkInData.bodyFatMeasureTestSessionSetup.bodyFatTestEventId        ,
        // assignedOperator: this.checkInData.bodyFatMeasureTestSessionSetup.bodyFatTestEventOperatorId,
        // uid: null,
        testDate: new Date(Date.now()).toISOString(),
        testTime: new Date(Date.now()).toISOString(),
        dryWeight: signupDetails.dryWeight,
        height: signupDetails.height,
        wetKg: signupDetails.wetKg,
        customerId: this.checkInData.bodyFatTestForCustomer.id,
        age: this.calculatedResults[0].age,
        birthDate: this.checkInData.bodyFatTestForCustomer.birthDate,
        sex: this.checkInData.bodyFatTestForCustomer.sex,
        gender: this.checkInData.bodyFatTestForCustomer.gender,
        ethnicity: this.checkInData.bodyFatTestForCustomer.ethnicity,
        emailAddress: this.checkInData.bodyFatTestForCustomer.emailAddress,
        waterTemperature: +this.checkInData.bodyFatMeasureTestSessionSetup.waterTemperature,
      })
      .subscribe(response => {
        isDevMode() && console.log('this.completedResults:', response);

        this.isResultsVisible.set(true);
        this.isTestingCompleteFlag = true;
        this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BFTESTS_COMPLETE_TEST}/${response.bodyFatMeasureTestReportId}`);
      });


  }

  public saveSetupTestDetails(signupDetails: BodyFatTestI): void {
    this.testingService.stepUpdated.next(this.testingStep);
    this.$gaService.event('submit_btn_test_soft', GoogleActionsEnum.USER_CLICKED_BTN);

    this.bodyMeasurementData = {
      dryWeight: signupDetails?.dryWeight,
      height: signupDetails?.height,
      wetWeightKg: signupDetails?.wetKg,
    };

    isDevMode() && console.log('customer testing component - saveSetupTestDetails');

    // this.proceedToReview();

    this.subscriptions.barrel = this.testingService
      .calculateTestData({
        isTraining: false,
        // note:null,
        assignedClub: this.checkInData.bodyFatMeasureTestSessionSetup.bodyFatTestEventClubId,
        assignedTruck: this.checkInData.bodyFatMeasureTestSessionSetup.bodyFatTestEventAssignedTruckId,
        assignedEventClub: this.checkInData.bodyFatMeasureTestSessionSetup.bodyFatTestEventClubId,
        assignedOperator: this.checkInData.bodyFatMeasureTestSessionSetup.bodyFatTestEventOperatorId,
        // uid: null,
        testDate: new Date(Date.now()).toISOString(),
        testTime: new Date(Date.now()).toISOString(),
        dryWeight: signupDetails.dryWeight,
        height: signupDetails.height,
        wetKg: signupDetails.wetKg,
        age: this.checkInData.bodyFatTestForCustomer.age,
        birthDate: this.checkInData.bodyFatTestForCustomer.birthDate,
        sex: this.checkInData.bodyFatTestForCustomer.sex,
        gender: this.checkInData.bodyFatTestForCustomer.gender,
        emailAddress: this.checkInData.bodyFatTestForCustomer.emailAddress,
        waterTemperature: +this.checkInData.bodyFatMeasureTestSessionSetup.waterTemperature,
        note: null,
        uid: null,
      })
      .subscribe(response => {
        this.calculatedResults = response;
        this.isResultsVisible.set(true);
      });
    this.testingService.testSessionData.next(this.testingService.testDataList[0]);
  }

  openModal() {
    // this.modalService.open(content, { backdropClass: 'light-blue-backdrop', size: 'lg' });
  }

  proceedToReview(): void {
    isDevMode() && console.log('go to review ');
    for (let i = 0; i < this.testingService.testDataList.length; i++) {
      this.testingService.testDataList[i].sessionDetails.testData = {
        dryWeight: this.bodyMeasurementData?.dryWeight,
        height: this.bodyMeasurementData?.height,
        wetKg: this.bodyMeasurementData?.wetWeightKg,
        // wetWeightKg: this.bodyMeasurementData.wetWeightKg,
        residualLungValue: this.calculatedResults[0].residualLungValue,
        fatLbs: this.calculatedResults[0].fatLbs,
        fatPercent: this.calculatedResults[0].fatPercent,
        leanLbs: this.calculatedResults[0].leanLbs,
        leanMassPercent: this.calculatedResults[0].leanMassPercent,
        waterLbs: this.calculatedResults[0].waterLbs,
        restingMetabolicRate: this.calculatedResults[0].restingMetabolicRate,
        isTraining: false,
      };
      this.testingService.testSessionData.next(this.testingService.testDataList[0]);
      isDevMode() &&
        console.log(
          'this.testingService.testDataList[i].sessionDetails.testData:',
          this.testingService.testDataList[i].sessionDetails.testData,
        );
      // this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BFTESTS_REVIEW_TEST}`);
    }
  }

  closeModal() {
    isDevMode() && console.log('modal');
    //   this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BFTESTS_DETAIL}/auth/clubs`);
    // this.modalService.dismissAll();
  }
  saveData() {
    // if (this.clubDetailsForm.valid) {
    //   this.createClub(this.clubDetailsForm.value);
    // }
  }
  isCompleteFlag(): boolean {
    isDevMode() && console.log('isCompleteFlag:');
    return true;
    //this.isTestingCompleteFlag;
    // Return true if testing is complete, otherwise false
  }

  // Call this method when the testing is complete
  sendCompletionStatus() {
    isDevMode() && console.log('sendCompletionStatus: id', this.calculatedResults[0]);
    // this.router.navigateByUrl(
    //   `${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BFTESTS_REVIEW_TEST}/${this.calculatedResults?.testDetails?.id}`,
    // );
    // console.log('sendCompletionStatus: id', this.calculatedResults.id);
    // this.completed.emit(this.calculatedResults.id);
  }

  ngOnInit(): void {
    this.getCheckInData();
  }

  getCheckInData(): void {
    const id = this.route.snapshot.paramMap.get('id') as string;
    this.subscriptions.barrel = this.testingService
      .findCheckIn(id)
      .subscribe(testSessionData => (this.checkInData = testSessionData));
  }
}
