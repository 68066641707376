<div class="row">
  <div class="col mx-auto">
    <h2 class="text-dark">License Status</h2>
    <p *ngIf="isValid" class="text-dark">Your license is valid.</p>
    <p *ngIf="!isValid" class="text-dark">Your license is invalid or expired.</p>
    <div class="">
      <button class="btn btn-info" (click)="checkLicense()">Check License</button>
      <button class="btn" (click)="generateLicense()">Generate License</button>
    </div>

  </div>
</div>
