import { ChangeDetectionStrategy, Component, effect, input, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { BodyanalyticsBaseComponent, MaterialModule, RouterUrlsEnum } from '@bodyanalytics/ui-core';

import { RegionsI } from '@bodyanalytics/data-models-ui';
import { RouterLink } from '@angular/router';
import { DatePipe, JsonPipe } from '@angular/common';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'bodyanalytics-manage-regions-table',
  templateUrl: './manage-regions-table.component.html',
  styleUrls: ['./manage-regions-table.component.scss'],
  standalone: true,
  // animations,
  providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }],

  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatTableModule, DatePipe, RouterLink, MatSortModule, JsonPipe, MaterialModule],
})
export class ManageRegionsTableComponent extends BodyanalyticsBaseComponent {
  protected regionDetail = RouterUrlsEnum.AUTH_ADMIN_MANAGE_REGIONS_DETAILS;

  protected displayedColumns = ['regionName', 'regionTerritoryType'];
  protected columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  protected expandedElement?: RegionsI;

  entities = input.required<RegionsI[]>();
   // Table data source
   dataSource = new MatTableDataSource<RegionsI>();

   @ViewChild(MatPaginator) paginator!: MatPaginator;
   @ViewChild(MatSort) sort!: MatSort;

   constructor() {
     super();
     // React to changes in entities and update the table's data source
     effect(() => {
       const data = this.entities(); // Get the updated entities
       this.dataSource.data = data; // Set it to the MatTableDataSource
     });
   }

   ngAfterViewInit() {
     this.dataSource.paginator = this.paginator;
     this.dataSource.sort = this.sort;
     // Workaround to notify the table about data changes
     this.dataSource.paginator.page.subscribe(() => this.updateTable());
     this.dataSource.sort.sortChange.subscribe(() => this.updateTable());
   }

   applyFilter(event: Event) {
     const filterValue = (event.target as HTMLInputElement).value;

     this.dataSource.filter = filterValue.trim().toLowerCase();
     if (this.dataSource.paginator) {
       this.dataSource.paginator.firstPage();
     }
     this.updateTable();
   }
   // Explicitly trigger change detection for pagination, sort, and filter
   private updateTable() {
     this.dataSource._updateChangeSubscription();
   }
 }
