import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  TestingEventI,
  BookingI,
  BookingRescI,
  RegionLocationI,
  TestEventSearchI,
} from '@bodyanalytics/data-models-ui';
import { MobileTestingCalendarService } from '../../services/mobile-testing.service';
import { BodyanalyticsBaseComponent, RouterUrlsEnum, SessionStorageService } from '@bodyanalytics/ui-core';
import { Store, select } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import * as TestEventActions from '../store/test-event.actions';
import { AppStateInterface } from '../types/appState.interface';
import {
  errorSelector,
  isLoadingSelector,
  testEventSearchSelector,
  testEventsSelector,
} from '../store/test-event.selectors';
import { DatePipe } from '@angular/common';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { AuthService } from '@auth0/auth0-angular';
import { format, utcToZonedTime } from 'date-fns-tz';

@Component({
  selector: 'bodyanalytics-calendar-search',
  templateUrl: './calendar-search.component.html',
  styleUrls: ['./calendar-search.component.scss'],
  providers: [DatePipe],
})
export class CalendarSearchComponent extends BodyanalyticsBaseComponent implements OnInit {
  housingLocationList: RegionLocationI[] = [];
  public selectedTime: any;
  filteredLocationList: RegionLocationI[] = [];

  public searchQuery$: Observable<TestEventSearchI | null>;
  isLoading$: Observable<boolean>;
  error$: Observable<string | null>;
  public loadingSession = false;

  currentSelectedTestEvent: TestingEventI | null = null;
  total: number = 0;
  testEvents: TestingEventI[] = [];
  bookingData: BookingI | null = null;
  existingOrderId: string | null = null;
  bookingResData: BookingRescI | null = null;
  testEventsData$: Observable<TestingEventI[]>;
  returnUrl!: string;
  searchQuery: string = '';

  constructor(
    private store: Store<AppStateInterface>,
    private mobileTestingCalendarService: MobileTestingCalendarService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private $gaService: GoogleAnalyticsService,
    private sessionStorageService: SessionStorageService,
    private auth: AuthService,
  ) {
    super();
    this.isLoading$ = this.store.pipe(select(isLoadingSelector));
    this.error$ = this.store.pipe(select(errorSelector));
    this.testEventsData$ = this.store.pipe(select(testEventsSelector));
    this.searchQuery$ = this.store.pipe(select(testEventSearchSelector));
  }
  ngOnInit(): void {
    this.$gaService.pageView('/search', 'Calendar');

    this.activatedRoute.params.subscribe(params => {
      const bookingId = params['id'];
      if (bookingId) {
        this.loadBookingDetails(bookingId);
      }
    });

    this.activatedRoute.data.subscribe(data => {
      if (data['booking']) {
        this.bookingData = data['booking'];
        this.$gaService.pageView('/search/' + this.bookingData!.id, 'Calendar Reschedule');
      }
    });
    // TODO: Fix rescheduling routing

    this.initializeReturnUrl();

    this.handleAuthentication();

    this.getTestEvents();
  }

  handleAuthentication(): void {
    this.auth.isAuthenticated$
      .pipe(
        tap(loggedIn => {
          console.log('is user logged in? ', loggedIn);
          if (loggedIn) {
            console.log('send them here:', this.returnUrl);
            this.router.navigateByUrl(this.returnUrl);
          } else if (this.returnUrl.includes('calendar/search')) {
            this.router.navigate([`${RouterUrlsEnum.CALENDER_EVENTS_SEARCH}`]);
          } else if (this.returnUrl.includes('signup-verify')) {
            this.router.navigate([`${RouterUrlsEnum.LOGIN}`]);
          } else {
            this.router.navigate([`${RouterUrlsEnum.CALENDER_EVENTS_SEARCH}`]);
            this.sessionStorageService.clear();
          }
        }),
      )
      .subscribe();
  }
  initializeReturnUrl(): void {
    const bookingData = this.sessionStorageService.getBookingData();
    const rescheduleBookingData = this.sessionStorageService.getRescheduleBookingData();

    const workflowStep = bookingData?.workflowStep;
    const eventId = bookingData?.selectedEvent?.id;

    console.log('rescheduleBookingData: ', rescheduleBookingData);
    if (rescheduleBookingData) {
      this.returnUrl = `${RouterUrlsEnum.CALENDER_EVENTS_SEARCH}/${rescheduleBookingData.existingBooking?.id}`;
    } else {
      switch (workflowStep) {
        case 'checkout':
          this.returnUrl = `${RouterUrlsEnum.CALENDER_CHECKOUT}${eventId}`;
          break;
        case 'signup-verify':
          this.returnUrl = `${RouterUrlsEnum.CALENDAR_SIGNUP_VERIFY}${eventId}`;
          break;
        default:
          this.returnUrl = `${RouterUrlsEnum.CALENDER_EVENTS_SEARCH}`; // Define a default URL if needed
          break;
      }
    }

    console.log('returnUrl: ', this.returnUrl);
  }
  loadBookingDetails(bookingId: string) {
    this.loadingSession = true;

    this.mobileTestingCalendarService.getBookingById(bookingId).subscribe(bookingDetails => {
      this.existingOrderId = bookingDetails.order[0]!.id;
      let timeZone = 'America/Chicago';
      if (this.bookingData!.slot.slotTime) {
        const centralTimeZone = 'America/Chicago';

        // Convert the UTC date to Central Time
        const centralDate = utcToZonedTime(this.bookingData!.slot.slotTime, centralTimeZone);

        // Format the date to display time in 12-hour format with AM/PM
        this.selectedTime = format(centralDate, 'h:mm:ss a', { timeZone: centralTimeZone });
       }

       this.loadingSession = false;

      this.mobileTestingCalendarService.setRescheduleBookingData(this.bookingData, this.existingOrderId);
      this.router.navigate([`${RouterUrlsEnum.CALENDER_EVENTS_SEARCH}/${this.bookingData?.id}`]);
    });
  }
  // Example function to convert ISO string to Central Time

  convertToCentralTime(utcDateString: string) {
    const date = new Date(utcDateString);

    return this.datePipe.transform(date, 'shortTime') as string;
  }
  // private formatDateWithoutZ(date: Date, ianatz: string): string {
  //   const timeZoneDate = formatInTimeZone(date, ianatz, 'HH:mm a');
  //   console.log('timeZoneDate:', timeZoneDate);
  //   return timeZoneDate; // Formats date as string without the 'Z'
  // }

  getTestEvents(searchQuery = '', searchType = '') {
    if (searchQuery) {
      this.searchQuery = searchQuery;
    }

    this.loadingSession = true;
    this.mobileTestingCalendarService.getTestingEvents(searchQuery, searchType).subscribe(testEvents => {
      this.testEvents = testEvents;
      this.loadingSession = false;
      this.store.dispatch(TestEventActions.testEventsLoaded({ testEvents }));
    });
  }

  filterResults(text: string) {
    if (!text) {
      this.filteredLocationList = this.housingLocationList;
      return;
    }

    this.filteredLocationList = this.housingLocationList.filter(housingLocation =>
      housingLocation?.city.toLowerCase().includes(text.toLowerCase()),
    );
  }
  updateTotals(selectedTestEvent: TestingEventI) {
    this.total = this.calculatedTotalCost(selectedTestEvent);
    // this.total = calculatedTestEventGrossEarnings(testEvent)
  }
  calculatedTotalCost(testEvent: TestingEventI) {
    return 0;
  }

  onCancel() {
    this.removeSelectedEvent();
  }

  onEventSelect(event: TestingEventI) {
    this.store.dispatch(
      TestEventActions.selectedEventById({
        testEventId: event.id,
      }),
    ); // Handle event selection logic, such as navigating to slot selection or updating the state
    this.currentSelectedTestEvent = event;
  }

  removeSelectedEvent() {
    this.store.dispatch(TestEventActions.clearSelectedEvent());
    this.currentSelectedTestEvent = null;
    // this.store.dispatch(TestEventActions.selectEvent({ id: eventId.id}));
  }
  // onSave(testEvent: TestingEventRequiredProps | TestingEventI) {

  onSave(testEvent: TestingEventI) {
    if ('id' in testEvent) {
      this.updateEvent(testEvent);
    } else {
      this.saveEventBook(testEvent);
    }
  }

  closeReschedule() {
    this.loadingSession = true;
    this.sessionStorageService.clearRescheduleBookingData();
    this.router.navigateByUrl(`${RouterUrlsEnum.CALENDER_EVENTS_SEARCH}`);
  }
  // saveEventBook(testEvent: TestingEventRequiredProps) {
  saveEventBook(testEventProps: TestingEventI) {
    this.store.dispatch(
      TestEventActions.createEvent({
        testEvents: testEventProps,
      }),
    );
    this.mobileTestingCalendarService.createTestEventReservation(testEventProps).subscribe(testEvents => {
      this.getTestEvents();
      this.removeSelectedEvent();
      this.store.dispatch(TestEventActions.testEventsCreated({ testEvents }));
    });
  }
  updateEvent(testEvent: TestingEventI) {
    this.store.dispatch(
      TestEventActions.updateEvent({
        testEventId: testEvent.id,
        changes: testEvent,
      }),
    );
    this.mobileTestingCalendarService
      .updateTestEventReservation(testEvent.id, testEvent)
      .subscribe(testEventResponse => {
        this.getTestEvents();
        this.removeSelectedEvent();

        this.store.dispatch(
          TestEventActions.testEventsUpdated({ testEventId: testEventResponse.id, changes: testEventResponse }),
        );
      });
  }

  onSearch(searchTerms: TestEventSearchI) {
    // searchTerm: string, preset: string
    // console.log(searchTerms.searchTerm);
    // this.searchQuery$ = searchTerms;

    // this.store.dispatch(TestEventActions.searchForEventByGymNameAction());
    // this.currentSelectedTestEvent = event;

    // this.store.dispatch(TestEventActions.testEventsSearchedByGymName({ gymName: searchTerm }));
    this.mobileTestingCalendarService.getTestingEventsByGymName(searchTerms.searchTerm).subscribe(testEvents => {
      this.getTestEvents();
      this.removeSelectedEvent();
      // this.store.dispatch(TestEventActions.addTestEvents({ testEvents: testEvents }));
      // this.currentSelectedTestEvent = event;

      this.store.dispatch(TestEventActions.testEventsSearchedByGymName({ testEvents: testEvents }));
    });
    // this.mobileTestingCalendarService.getTestingEventsByGymName(searchTerm);
  }

  onSelect(testEvent: TestingEventI) {
    console.log('selected: ', testEvent.id);
    this.store.dispatch(
      TestEventActions.selectedEventById({
        testEventId: testEvent.id,
      }),
    );
    this.currentSelectedTestEvent = testEvent;
    this.router.navigateByUrl(`${RouterUrlsEnum.CALENDER_EVENT_DETAILS}/${this.currentSelectedTestEvent.id}`);
    // this.mobileTestingCalendarService.deleteTestEventReservation(testEvent.id).subscribe(() => {
    //   this.getTestEvents();
    //   this.removeSelectedEvent();
    //   this.store.dispatch(TestEventActions.testEventsDeleted({ testEventId: testEvent.id }));
    // });
  }

  onSelectedEvent(formValues: any) {}
  onDelete(testEvent: TestingEventI) {
    this.store.dispatch(
      TestEventActions.deleteEvent({
        testEventId: testEvent.id,
      }),
    );
    this.mobileTestingCalendarService.deleteTestEventReservation(testEvent.id).subscribe(() => {
      this.getTestEvents();
      this.removeSelectedEvent();
      this.store.dispatch(TestEventActions.testEventsDeleted({ testEventId: testEvent.id }));
    });
  }
  handleFormSubmission(formValues: any): void {
    const region = formValues.region;
    const gymName = formValues.gymName;
    // Retrieve other form values as needed
    // this.selectedRegionName = formValues.region;
    // Use the form values to perform the desired action (e.g., API call)
    // this.searchForAllEvents(region, gymName);
  }

  addTestEventToCart(product: any) {
    console.log('addTestEventToCart', product.testEventCustomerSignupsId);
    // this.isThisStepDone = true;
    // // this.firstFormGroup.patchValue({
    // //   testingevent_id: product.testingevent_id,
    // // });
    // // this.cart.addLine(product, 1);
    // this.isClubSelected = true;

    // this.subscriptions.barrel = this.mobileTestingCalendarService
    //   .getTestingEvent(product.eventId)
    //   .subscribe((results: TestingEventI) => {
    //     this.testEventData = results;
    //     // this.searchedEventCount = this.testEvents.length
    //   });

    const eventSelectedId = '1';
    this.router.navigateByUrl(`${RouterUrlsEnum.CALENDER_SIGNUP}/${product.testEventCustomerSignupsId}`);
  }

  searchForAllEvents(regionName: string, gymName?: string) {
    //https://bodyanalyticstx.com/GetClubDates?region=Dallas&zipcode=&gym=0&latitude=32.776664&longitude=-96.796988&radius=10000
    //     region: Dallas
    // zipcode:
    // gym: 0
    // latitude: 32.776664
    // longitude: -96.796988
    // radius: 10000

    // Convert to uppercase
    let regionFormatted = regionName.toUpperCase();

    // Remove spaces
    regionFormatted = regionFormatted.replace(/\s+/g, '_');

    // Remove special characters
    regionFormatted = regionFormatted.replace(/[^a-zA-Z]/g, '_');

    // this.subscriptions.barrel = this.mobileTestingCalendarService
    //   .getTestingEventsBySearchRegion(regionFormatted, gymName)
    //   .subscribe((results: TestingEventI[]) => {
    //     this.testEventsDataArr = results;
    //     this.searchedEventCount = this.testEventsDataArr.length;
    //   });
  }

  applyFilter(event: { searchQuery: string, searchType: string }): void {
    this.getTestEvents(event.searchQuery, event.searchType);
  }
}
