import { HttpHeaders, HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENV } from '@bodyanalytics/app-config';
import { LicenseDetailsI } from '@bodyanalytics/data-models-ui';
import { ApiLocalRoutesEnum, SharedLogService, SharedErrorService } from '@bodyanalytics/ui-core';
import { Observable, tap, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {
  private env = inject(ENV);

  private licensingUrl = `${ApiLocalRoutesEnum.API_SUFFIX_LICENSE}`;
  private licensingValidateUrl = `${ApiLocalRoutesEnum.API_SUFFIX_LICENSE_VALIDATE}`;
  private licensingGenerateUrl = `${ApiLocalRoutesEnum.API_SUFFIX_LICENSE_GENERATE}`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    private sharedLogService: SharedLogService,
    private sharedErrorService: SharedErrorService,
  ) {}
  generateLicense(emailAddress: string, validityDays: number): Observable<{ licenseKey: string }> {
    const url = `${this.env.api}/${this.licensingGenerateUrl}`;
    return this.http.post<{ licenseKey: string }>(url, { emailAddress, validityDays }, this.httpOptions).pipe(
      tap(({ licenseKey }) => this.sharedLogService.log(`added licenseKey=${licenseKey}`)), // Log the licenseKey
      catchError(this.sharedErrorService.handleError<{ licenseKey: string }>('generateLicense')),
    );
  }

  validateLicense(licenseKey: string): Observable<{ isValid: boolean }> {
    const url = `${this.env.api}/${this.licensingValidateUrl}`;
    return this.http
      .get<{ isValid: boolean }>(url, {
        params: { licenseKey },
      })
      .pipe(
        tap(_ => console.log('fetched validateLicense')),
        catchError(this.sharedErrorService.handleError<{ isValid: boolean }>('validateLicense', { isValid: false })),
      );
  }

  getLicenseFromServer(): Observable<LicenseDetailsI> {
    const url = `${this.env.api}/${this.licensingUrl}`;

    return this.http.get<LicenseDetailsI>(`${url}/get-license`);
  }
}
