import { Routes } from '@angular/router';
import { ServerErrorComponent } from '@bodyanalytics/ui-core';
import { dashboardUiRoute } from '@bodyanalytics/dashboard-ui';
import { adminDashboardUiRoute } from '@bodyanalytics/admin-dashboard-ui';
import { manageBodymeasurementsRoute } from '@bodyanalytics/front/admin/manage-bodymeasurements/feature';
import { manageClubsUiRoutes } from '@bodyanalytics/front/admin/manage-clubs/feature';
import { LoginComponent } from '@bodyanalytics/authentication-ui';
import { manageCustomersUiRoutes } from '@bodyanalytics/front/admin/manage-customers/feature';
import { manageBookingUiRoutes } from '@bodyanalytics/front/admin/manage-bookings/feature';
import { manageOrdersUiRoutes } from '@bodyanalytics/front/admin/manage-orders/feature';
import { manageRegionsUiRoutes } from '@bodyanalytics/front/admin/manage-regions/feature';
import { managePaymentsUiRoutes } from '@bodyanalytics/front/admin/manage-payments/feature';
import { manageDiscountsUiRoutes } from '@bodyanalytics/front/admin/manage-discounts/feature';
import { manageLicenseUiRoutes } from '@bodyanalytics/front/admin/license/feature';
import { adminRegisterUiRoutes } from '@bodyanalytics/front/admin/registration/feature';
import { homeUiRoutes } from '@bodyanalytics/front/shared/home/feature';
export default [
  homeUiRoutes,
  { path: 'login', component: LoginComponent },
  { path: 'error', component: ServerErrorComponent },
  adminDashboardUiRoute,
  adminRegisterUiRoutes,
  dashboardUiRoute,
  manageBodymeasurementsRoute,
  manageClubsUiRoutes,
  manageCustomersUiRoutes,
  manageBookingUiRoutes,
  manageOrdersUiRoutes,
  manageRegionsUiRoutes,
  managePaymentsUiRoutes,
  manageDiscountsUiRoutes,
  manageLicenseUiRoutes,
  {
    path: 'access-denied',
    loadComponent: () => import('@bodyanalytics/ui-core').then(mod => mod.AccessDeniedComponent),
  },
  { path: '**', redirectTo: homeUiRoutes.path },
] satisfies Routes;
