import { BookingI } from './booking';
import { ClubI } from './club';
import { EventProductServiceI, ProductI } from './products';
import { ServicesI } from './services';
export enum EVENT_STATUS {
  OK,
  CANCELLED,
  PLANNED,
  VERIFIED,
  CONFIRMED,
}


// export enum RegionterritoryI{
//   ABX,
//   BodyAnalytics,
//   EmployeeOwned,
//   Shared
// }

export interface EventStatusDataI {
  id: string;
  status: EVENT_STATUS;
}
export interface EventPrimaryDataI {
  body: string;
  earnings: number;
  // club_testingevent_id: string;
  testEventName: string;
  createdAt: string;
  // creator_id: string;
  testEventDescription: string;
  imageUrl: string;
  // end_time: null;
  // event_date: string;
  startTime: string;
  endTime: string;
  testEventDate: string;
  id: string;
  testEventCustomerSignupsId: string;
  slotIntreval: string;
  slug: string;
  // start_time: string;
  status: string;
  // testingevent_creator_id: string;
  // testingevent_id: string;
  title: string;
  updatedAt: string;
  testEventAtClub: ClubI;
  assignedTruckId: string;
  assignedOperatorId: string;
  isAllowedSignupOnline: boolean;
  isPaymentRequired: boolean;
  isTestingEventPrivate: boolean;
  assignedOperator: string;
  assignedTruck: string;
  assignedClub: string;
  testEventAtClubId: string;
  signupInterval: string;

  // slotTime: string | null | undefined;
  // id: string;
  // testEventName: string;
  // testEventDate: string;
  // startTime: string;
  // endTime: string;
  // status: TestingEventStatusEnum.DRAFT;
  // assignedOperator?: string;
  // signupInterval?: string;
  // isAllowedSignupOnline?: boolean;
  // isPaymentRequired?: boolean;
  // isTestingEventPrivate?: boolean;
  // testEventDescription?: string;
  // testEventAtClub: ClubI;
  // testEventAtClubId?: string;
  // clubId?: string;
  // testEventSlots: SlotI[];
  // location:{
  //   latitude: number,
  //   longitude: number
  // }
  // "testEventName": "ClubNienow - MillerEvent",
  // "testEventDescription": "Fitness Challenge 19",
  // "testEventDate": "Fri Jan 05 2024",
  // "startTime": "2023-12-18T22:25:38-06:00",
  // "endTime": "2023-12-20T09:47:24-06:00",
}
// "testEventName": "ClubNienow - MillerEvent",
// "testEventDescription": "Fitness Challenge 19",
// "testEventDate": "Fri Jan 05 2024",
// "startTime": "2023-12-18T22:25:38-06:00",
// "endTime": "2023-12-20T09:47:24-06:00",
// "status": "OK",
// "signupInterval": "TEN_MIN",
// "isAllowedSignupOnline": true,
// "isPaymentRequired": true,
// "isTestingEventPrivate": false,
// "testEventAtClubId": null,
// "assignedOperatorId": null,
// "assignedTruckId": null,
// "testEventNoteId": null,
// "testEventForNutritionChallengeId": null,
// "testEventForCheckInId": null,
// "testEventWithDiscountId": null,
// "createdAt": "2023-12-19T21:57:35.995Z",
// "updatedAt": "2023-12-19T21:57:35.995Z",
// "region_id": null,
// "testEventCustomerSignupsId": null,
// "regionId": null,
// "signupId": null,
// "signupSlug": null,
// "testEventAtClub": null,
// "availableServices": [],
// "availableSlots": []

export interface CreateTestEventI {
  id?: string;
  testEventDate: string;
  startTime: string;
  endTime: string;
  isAllowedSignupOnline: boolean;
  isPaymentRequired: boolean;
  isTestingEventPrivate: boolean;
  assignedOperator: string;
  assignedTruck: string;
  assignedClub: string;
  signupInterval: string;
  createdEvent?: TestingEventI;
  // slots?:SlotI
}
export interface TestingEventRequiredProps {
  testEventName: string;
  createdAt: string;
  // creator_id: string;
  testEventDescription: string;
  // end_time: null;
  // event_date: string;
  startTime: string;
  endTime: string;
  testEventDate: string;
  id: string;
  testEventCustomerSignupsId: string;
  slotIntreval: string;
  slug: string;
  // start_time: string;
  status: string;

  title: string;
  updatedAt: string;
  testEventAtClub: ClubI;
  assignedTruckId: string;
  assignedOperatorId: string;
  isAllowedSignupOnline: boolean;
  isPaymentRequired: boolean;
  isTestingEventPrivate: boolean;
  assignedOperator: string;
  assignedTruck: string;
  assignedClub: string;
  testEventAtClubId: string;
  signupInterval: string;
}
export interface PromoCodeI {
  // id: string;
  // object: string;
  percent_off: null | number;
  discountAmount: null | number;
  discountCode: string;
  isCustomerDiscount: boolean;
  isPackage: boolean;
  maxRedeemption: number
  // active: boolean;
  // code: string;
  // coupon: {
  //   id: string;
  //   object: string;
  //   amount_off: null | number;
  //   created: string;
  //   currency: null;
  //   duration: string;
  //   duration_in_months: number;
  //   livemode: boolean;
  //   max_redemptions: null;
  //   metadata: {};
  //   name: string;
  //   percent_off: number;
  //   redeem_by: null;
  //   times_redeemed: number;
  //   valid: boolean;
  // };

  // expires_at: string;
  // livemode: boolean;
  // max_redemptions: null;

  // restrictions: {
  //   first_time_transaction: boolean;
  //   minimum_amount: number;
  //   minimum_amount_currency: string;
  // };
  // times_redeemed: number;
}

export interface OrderConfirmationI {
  id: string;
  orderNumber: string;
  orderSummaryId?: string;
  selectService?: string;
  subtotal?: number;
  quantity?: number;
  serviceFee?: number;
  total?: number;
  orderDate: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  gymName?: string;
  eventDate?: string;
  selectedSlotTime?: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  orderDetails: OrderDetailsI[];
  ordersForTestingEvent: TestingEventI;
  booking: BookingI;
  // "lastName": "Parisian",
  // "emailAddress": "Parisian",
  // "address": "4583 Monahan Cape",
  // "country": "USA",
  // "city": "New Malvina",
  // "zipCode": "37688-3864",
  // "required_date": "2023-12-14T00:00:00.000Z",
  // // "employee_id": null,
  // "shipped_date": "2024-11-27T00:00:00.000Z",
  // "ship_via": null,
  // "freight": null,
  // "customerId": null,
  // "createdAt": "2023-12-13T20:34:37.975Z",
  // "updatedAt": "2023-12-13T20:34:37.975Z",
  // "bodyFatMeasureTestProductId": null,
  // "paymentId": null,
  // "operatorId": null,
  // "linksId": null,
  // "testingEventId": null,
  // "mealPlanId": null,
  // "trainerId": null
}

// export interface BookingI {}
export interface OrderDetailsI {
  productId: string;
  price: number;
  quantity: number;
  salesTax: number;
  serviceFee: number;
  subTotal: number;
  totalAmount: number;
  products: ProductI;
  productsPurchased: EventProductsI;
}

export interface EventProductsI{
  id: string;
  stripeProductId: string;
  stripeProductName: string;
  description?: string;

}

export interface TestEventSearchI {
  preset: string[];
  searchTerm: string;
}
export interface RegionLocationI {
  id: number;
  name: string;
  city: string;
  state: string;
  photo: string;
  availableUnits: number;
  // wifi: boolean;
  // laundry: boolean;
}
export interface TestingEventI {
  body: string;
  earnings: number;
  // club_testingevent_id: string;
  testEventName: string;
  createdAt: string;
  // creator_id: string;
  testEventDescription: string;
  imageUrl: string;
  // end_time: null;
  // event_date: string;
  startTime: string;
  endTime: string;
  testEventDate: string;
  id: string;
  testEventCustomerSignupsId: string;
  slotIntreval: string;
  slotIntrevalValue: number;
  slug: string;
  // start_time: string;
  status: string;
  // testingevent_creator_id: string;
  // testingevent_id: string;
  title: string;
  updatedAt: string;
  testEventAtClub: ClubI;
  assignedTruckId: string;
  assignedOperatorId: string;
  isAllowedSignupOnline: boolean;
  isPaymentRequired: boolean;
  isTestingEventPrivate: boolean;
  assignedOperator: string;
  assignedTruck: string;
  assignedClub: string;
  testEventAtClubId: string;
  signupInterval: string;
  EventProducts: EventProductServiceI[];
  // services: ServicesI[];
  // products: ProductI[];
  // slotTime: string | null | undefined;
  // id: string;
  // testEventName: string;
  // testEventDate: string;
  // startTime: string;
  // endTime: string;
  // status: TestingEventStatusEnum.DRAFT;
  // assignedOperator?: string;
  // signupInterval?: string;
  // isAllowedSignupOnline?: boolean;
  // isPaymentRequired?: boolean;
  // isTestingEventPrivate?: boolean;
  // testEventDescription?: string;
  // testEventAtClub: ClubI;
  // testEventAtClubId?: string;
  // clubId?: string;
  // testEventSlots: SlotI[];
  // location:{
  //   latitude: number,
  //   longitude: number
  // }
  availableSlots: SlotI[];
  availableProducts: ProductI[];
  availableServices: ServicesI[];
}
export interface SelectedServiceI {
  serviceId: number;
  // slot: string; // Reference to the chosen time slot, could be more complex
  // You can include additional properties like serviceName, price, etc., for ease of display
  serviceName: string;
  price: number;
  selectedSlot: TimeSlotI;
  // provider: string; // Name of the provider chosen by the customer
  additionalRequests: string; // Any additional requests or preferences

  // Other properties like provider name, special requests, etc.
}
export interface ProviderAvailabilityI {
  dayOfWeek: number; // 0 (Sunday) to 6 (Saturday)
  startTime: string; // Start time of availability in HH:MM format
  endTime: string; // End time of availability in HH:MM format
}

export interface ContactInfoI {
  email: string;
  phone: string;
  address: string; // Could be the address of the salon/spa/clinic
}
export interface ProviderI {
  id: number;
  name: string;
  bio: string; // A brief biography or introduction of the provider
  imageUrl: string; // URL for the provider's profile picture
  rating: number; // Average rating based on customer feedback
  specialties: number[]; // Array of service IDs that the provider specializes in
  availability: ProviderAvailabilityI[]; // Provider's availability schedule
  contactInfo: ContactInfoI; // Contact information for the provider
  languages: string[]; // Languages spoken by the provider
  yearsOfExperience: number; // Years of experience in their field
}

export interface CategoryI {
  name: string;
  id: string;
  description?: string; // Optional description of the category
  imageUrl?: string; // Optional image URL for the category
  // Additional properties like description, icon, etc., can be included
  featuredServices: number[]; // IDs of featured services for quick access
}

export interface TimeSlotI {
  id: string; // Unique identifier for the time slot
  startTime: Date; // Start time of the slot
  slotDate?: Date; // Date time of the slot
  slotTime?: Date; // Time of the slot
  slotStartTime?: Date; // Start time of the slot
  slotEndTime?: Date; // End time of the slot
  isSelected?: boolean; // Indicates if the slot is available for booking
  endTime: Date; // End time of the slot
  isAvailable: boolean; // Indicates if the slot is available for booking
  // maxBookings: number; // Maximum number of concurrent bookings allowed for this slot
  maxCapacity: number; // Maximum number of bookings allowed in this slot
  currentBookings: number; // Current number of bookings in this slot
  // Additional properties as needed
  providersAvailable: ProviderI[];
  // time: string; // Could be a string like '9:00 AM - 10:00 AM' or a more complex structure
  // // Additional properties like slotId, availability, etc., can be added
}

export enum TestingEventStatusEnum {
  DRAFT = 'Draft',
  PLANNED = 'Planned',
  CREATED = 'Created',
  SCHEDULLED = 'Scheduled',
  CANCELLED = 'Cancelled',
}
export interface SlotNI {
  slotTime: Date | string;
  startTime: string;
  endTime: Date | string;
  isAvailable: boolean;
  isSelected: boolean;
}
export interface SlotI {
  id: string;
  isAvailable: boolean;
  isSelected: boolean;
  slotDate?: string;

  startTime: Date| string;
  slotEndTime?: string;
  slotStartTime?: string;
  slotTime?: string;
  testingEventId?: string;
  slotsSignupScheduledTimesId?: string;
  name?: string;
  num?: number;
}

// "id": "29a968f4-d29c-4a05-8e09-0fb175d7e4c8",
// "slotDate": "2023-12-09T14:30:00.000Z",
// "slotStartTime": "2023-12-09T14:30:00.000Z",
// "slotEndTime": "2023-12-09T14:45:00.000Z",
// "slotTime": "2023-12-09T14:30:00.000Z",
// "isAvailable": true,
// "slotsForTestingEventId": "0097d2f1-26a3-4d87-95b0-ce51d549d50d",
// "signupAvailabilityId": "d62eb9d9-e978-47f2-962f-3907ae672e4a",
// "signupAvailabilityTestingEventId": "0097d2f1-26a3-4d87-95b0-ce51d549d50d"
